import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import "toastr/build/toastr.min.css"

import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser } from "../../store/actions"

// import images
import MetaTag from "components/Common/Meta-tag"
import { LocalLogo, profile } from "assets/images"
import loginBg from "assets/images/Defualt/loginBg.png"
import { MdOutlineStorefront } from "react-icons/md"

const Login = ({ history }) => {
  const dispatch = useDispatch()

  const { error, user, loading, settings } = useSelector(state => ({
    error: state.Login.error,
    user: state.Login.user,
    loading: state.Login.loading,
    settings: state.Settings.settings,
  }))

  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(loginUser(values, history))
  }

  const token = sessionStorage.getItem("token")

  useEffect(() => {
    if (token) {
      history.push("/dashboard")
    }
  }, [])

  const [show, setShow] = useState(false)

  return (
    <React.Fragment>
      <MetaTag title="Login" />

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            {/* <Col md={8} lg={6} xl={8}> */}
            <Card
              className="overflow-hidden new-login-screen px-0 w-75"
              style={{ height: "70vh", borderRadius: "15px" }}
            >
              <CardBody className="p-0 d-flex w-100 align-items-center justify-content-center">
                <div
                  className="w-50 h-100 login-background"
                  style={{ backgroundColor: "#41655B", position: "relative" }}
                >
                  <Link
                    to="/"
                    className="auth-logo-light"
                    style={{
                      // backgroundColor: "#41655B",
                      position: "absolute",
                    }}
                  >
                    <div className="m-auto profile-user-wi">
                      <span
                        className="avatar-title "
                        style={{
                          backgroundColor: "transparent",
                        }}
                      >
                        <img
                          src={settings?.company?.logo[0]?.url ?? LocalLogo}
                          alt="logo"
                          height="50"
                        />
                      </span>
                    </div>
                  </Link>{" "}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={loginBg}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      alt="login-bg"
                    />
                  </div>
                </div>
                <div className="p-4 w-50 sm:w-100">
                  <h5>Welcome Back !</h5>
                  <p>Sign in to continue</p>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(v)
                    }}
                  >
                    {/* <Card
                      className={`border card border-info ${
                        isStoreAdminLogin ? "bg-info text-white" : ""
                      }`}
                    >
                      <CardBody className="d-flex align-items-center p-2 justify-content-between">
                        <div>
                          <label
                            className="mb-0 pb-0"
                            htmlFor="storeAdminRadio"
                          >
                            <MdOutlineStorefront
                              className="me-1 color-info"
                              size={"15px"}
                            />
                            Log in as a store administrator
                          </label>
                        </div>

                        <AvField
                          id="storeAdminRadio"
                          name="isStoreAdmin"
                          label=""
                          placeholder=""
                          type="radio"
                          style={{
                            height: "17px",
                            width: "17px",
                          }}
                          className="m-0 "
                          onChange={e => setIsStoreAdminLogin(e.target.checked)}
                          checked={isStoreAdminLogin}
                        />
                      </CardBody>
                    </Card> */}

                    {error?.message ? (
                      <Alert color="danger">{error.message}</Alert>
                    ) : null}

                    <div className="mb-3">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter username"
                        type="text"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="password"
                        label="Password"
                        type={show ? "text" : "password"}
                        required
                        placeholder="Enter Password"
                      />
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                        onChange={e => setShow(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Show Password
                      </label>
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                        style={{
                          backgroundColor: "#41655B",
                          color: "#D1C2AB",
                        }}
                        className="btn  btn-block"
                        type="submit"
                      >
                        {loading ? (
                          <>
                            <Spinner color="white" />
                          </>
                        ) : (
                          "Log In"
                        )}
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <Link to="#!" className="text-muted">
                        <i className="mdi mdi-lock me-1" />
                        Forgot your password?
                      </Link>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                © {new Date().getFullYear()} {}. Crafted with{" "}
                <i className="mdi mdi-heart text-danger" /> by
                <a target="blank" href="https://osperb.com">
                  {` osperb`}
                </a>
              </p>
            </div>
            {/* </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
