// //promoters
// /* PROMOTERS */

// export const GET_PROMOTERS = "GET_PROMOTERS"
// export const GET_PROMOTERS_SUCCESS = "GET_PROMOTERS_SUCCESS"
// export const GET_PROMOTERS_FAIL = "GET_PROMOTERS_FAIL"

// /* PROMOTER_DETAILS */
// export const GET_PROMOTER_DETAILS = "GET_PROMOTER_DETAILS"
// export const GET_PROMOTER_DETAILS_SUCCESS = "GET_PROMOTER_DETAILS_SUCCESS"
// export const GET_PROMOTER_DETAILS_FAIL = "GET_PROMOTER_DETAILS_FAIL"

// /* new PROMOTERS */
// export const CREATE_PROMOTER = "CREATE_PROMOTER"
// export const CREATE_PROMOTER_SUCCESS = "CREATE_PROMOTER_SUCCESS"
// export const CREATE_PROMOTER_FAIL = "CREATE_PROMOTER_FAIL"

// /* Edit PROMOTER*/
// export const UPDATE_PROMOTER = "UPDATE_PROMOTER"
// export const UPDATE_PROMOTER_SUCCESS = "UPDATE_PROMOTER_SUCCESS"
// export const UPDATE_PROMOTER_FAIL = "UPDATE_PROMOTER_FAIL"

// /* Delete PROMOTER*/
// export const DELETE_PROMOTER = "DELETE_PROMOTER"
// export const DELETE_PROMOTER_SUCCESS = "DELETE_PROMOTER_SUCCESS"
// export const DELETE_PROMOTER_FAIL = "DELETE_PROMOTER_FAIL"

// /* check promoter id PROMOTER*/
// export const CHECK_PROMOTERID = "CHECK_PROMOTERID"
// export const CHECK_PROMOTERID_SUCCESS = "CHECK_PROMOTERID_SUCCESS"
// export const CHECK_PROMOTERID_FAIL = "CHECK_PROMOTERID_FAIL"

// // pageview Details
// export const GET_PAGE_VIEW_DETAILS = "GET_PAGE_VIEW_DETAILS"
// export const GET_PAGE_VIEW_DETAILS_SUCCESS = "GET_PAGE_VIEW_DETAILS_SUCCESS"
// export const GET_PAGE_VIEW_DETAILS_FAIL = "GET_PAGE_VIEW_DETAILS_FAIL"

// // Earning

// export const GET_EARINIG_DETAILS = "GET_EARINIG_DETAILS"
// export const GET_EARINIG_DETAILS_SUCCESS = "GET_EARINIG_DETAILS_SUCCESS"
// export const GET_EARINIG_DETAILS_FAIL = "GET_EARINIG_DETAILS_FAIL"

// // All page view

// export const GET_PAGE_VIEW = "GET_PAGE_VIEW"
// export const GET_PAGE_VIEW_SUCCESS = "GET_PAGE_VIEW_SUCCESS"
// export const GET_PAGE_VIEW_FAIL = "GET_PAGE_VIEW_FAIL"

// // All Earnings

// export const GET_EARNING = "GET_EARNING"
// export const GET_EARNING_SUCCESS = "GET_EARNING_SUCCESS"
// export const GET_EARNING_FAIL = "GET_EARNING_FAIL"

// // All Formatted Earnings

// export const GET_ALL_FORMATTED_EARNINGS = "GET_ALL_FORMATTED_EARNINGS"
// export const GET_ALL_FORMATTED_EARNINGS_SUCCESS =
//   "GET_ALL_FORMATTED_EARNINGS_SUCCESS"
// export const GET_ALL_FORMATTED_EARNINGS_FAIL = "GET_ALL_FORMATTED_EARNINGS_FAIL"

// page view

export const PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS =
  "PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS"
export const PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS =
  "PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS"
export const PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL =
  "PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL"

// earnings

export const GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS =
  "GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS"
export const GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS =
  "GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS"
export const GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL =
  "GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL"

// product list

export const GET_PROMOTER_PRODUCT = "GET_PROMOTER_PRODUCT"
export const GET_PROMOTER_PRODUCT_SUCCESS = "GET_PROMOTER_PRODUCT_SUCCESS"
export const GET_PROMOTER_PRODUCT_FAIL = "GET_PROMOTER_PRODUCT_FAIL"

// product details

export const GET_PROMOTER_PRODUCT_DETAILS = "GET_PROMOTER_PRODUCT_DETAILS"
export const GET_PROMOTER_PRODUCT_DETAILS_SUCCESS =
  "GET_PROMOTER_PRODUCT_DETAILS_SUCCESS"
export const GET_PROMOTER_PRODUCT_DETAILS_FAIL =
  "GET_PROMOTER_PRODUCT_DETAILS_FAIL"
