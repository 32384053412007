import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce, update } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap"
import {
  createReview,
  getAllProducts,
  getRawUsers,
  updateReview,
} from "store/actions"
import Select from "react-select"
import MyRating from "components/Common/Rating"
import UseImageUploader from "components/imageUploader/useImageUploader"

const ReviewModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const { prodLoading, products, users, usersLoading } = useSelector(state => ({
    prodLoading: state.Products.loading,
    products: state.Products.products,
    users: state.Users.rawUsers?.users,
    usersLoading: state.Users.loading,
  }))

  const [productSelect, setProductSelect] = useState({})
  const [productSearch, setProductSearch] = useState()

  const [userSearchText, setUserSearchText] = useState("")
  const [selectedUserLabel, setSelectedUserLabel] = useState("Search user")
  const [user, setUser] = useState()

  const [rating, setRating] = useState(0)

  const handleValidSubmit = (e, v) => {
    const review = {
      ...v,
      rating: rating,
      product: productSelect?.id,
      images: imagesPreview,
    }
    if (updateData?._id) {
      dispatch(updateReview(review, onCloseClick, updateData?._id))
    } else {
      dispatch(createReview(review, onCloseClick))
    }
  }

  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", productSearch))
  }, [dispatch, productSearch])

  //product dropdown
  const debounceProductSearch = debounce(value => setProductSearch(value), 600)

  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  const [imagesPreview, setImagesPreview] = useState([])

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setProductSelect(product)
  }

  const productOptions = (products?.length >= 1 ? products : [])?.map(
    (result, index) => ({
      key: index,
      label: result?.primaryLang?.name,
      value: result?._id,
    })
  )

  useEffect(() => {
    if (updateData?._id) {
      setProductSelect({
        title: updateData?.product?.primaryLang?.name,
        value: updateData?.product?._id,
      })
      setRating(updateData?.rating)
      setUser({
        name: updateData?.name,
        phone: updateData?.phone,
      })
      setImagesPreview(updateData?.images)
    }
  }, [updateData])

  useEffect(() => {
    dispatch(getRawUsers(userSearchText))
  }, [dispatch, userSearchText])

  const debounceUserSearch = debounce(value => setUserSearchText(value), 600)
  const handleUserEnters = textEntered => {
    debounceUserSearch(textEntered)
  }

  function handlerUserFinalValue(event) {
    setSelectedUserLabel(event.label)
    setUser({
      name: event?.name,
      phone: event?.phone,
    })
  }

  const userOptions = [
    {
      options:
        users?.length &&
        users?.map((result, index) => ({
          key: index,
          label: `${result?.phone} ${result?.name ? "- " + result?.name : ""}`,
          value: result?._id,
          name: result?.name ? result?.name : "",
          phone: result?.phone,
        })),
    },
  ]

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>Add New Review</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col className="mt-3 col-12">
              <p className="h4 mb-2 text-muted font-size-14">Select User</p>
              <FormGroup className="col-12">
                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                  <Select
                    onInputChange={handleUserEnters}
                    value={selectedUserLabel}
                    placeholder={selectedUserLabel}
                    onChange={handlerUserFinalValue}
                    options={userOptions}
                    classNamePrefix="select2-selection"
                    isLoading={usersLoading}
                  />
                </div>
              </FormGroup>
            </Col>
            {/* <Col lg={4} className="mt-3">
              <p className="h4 mb-2 text-muted font-size-14">&nbsp;</p>
              <button
                type="button"
                className={`btn disabled btn-light text-danger `}
              >
                or
              </button>
              <button
                onClick={() => {
                  setSelectedUserLabel("Search user")
                  setSelectedUserId("")
                }}
                type="button"
                className={`btn  ms-2 ${
                  newUser ? "btn-info" : "btn-outline-info"
                } `}
              >
                new user
              </button>
            </Col> */}
            <>
              <Col className="col-6">
                <AvField
                  name="name"
                  type="text"
                  placeholder="Enter Name"
                  value={user?.name}
                />
              </Col>
              <Col className="col-6">
                <AvField
                  name="phone"
                  type="number"
                  placeholder="Enter Phone"
                  value={user?.phone}
                  validate={{
                    minLength: {
                      value: 10,
                      errorMessage: "phone number must be 10 letter ",
                    },
                    maxLength: {
                      value: 10,
                      errorMessage: "phone number must be 10 letter ",
                    },
                  }}
                />
              </Col>
            </>

            <Col lg={12} className="mt-3">
              <p className="h4 mb-2 text-muted font-size-14">Products</p>
              <FormGroup className="mb-3">
                <div className="ajax-select mt-lg-0 select2-container">
                  <Select
                    onInputChange={handleProductEnters}
                    value={productSelect?.title}
                    placeholder={productSelect?.title}
                    onChange={handlerProductFinalValue}
                    options={productOptions}
                    classNamePrefix="select2-selection"
                    isLoading={prodLoading}
                  />
                </div>
              </FormGroup>
            </Col>

            <Col lg={12}>
              <MyRating
                className="text-warning mb-3"
                initialRating={rating}
                onChange={value => setRating(value)}
              />
            </Col>
            <Col lg={12}>
              <AvField
                name="message"
                type="textarea"
                placeholder="Enter Message"
                style={{
                  height: 80,
                }}
                value={updateData?.message}
              />
            </Col>
            <Col lg={12}>
              <UseImageUploader
                imageSize={4}
                imagesPreview={imagesPreview}
                setImagesPreview={setImagesPreview}
                uploadUrl={"/products"}
                isMultiple
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

ReviewModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default ReviewModal
