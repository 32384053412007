import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from "../../../components/Common/MyPagination"

//actions
import { getUnapprovedStores } from "store/stores/actions"

import "../../../assets/scss/datatables.scss"

const QuarantinedStores = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  //unapprovedStores
  const { unapprovedStores, total, loading } = useSelector(state => ({
    unapprovedStores: state.Stores.unapprovedStores.stores,
    total: state.Stores.unapprovedStores.total,
    loading: state.Stores.loading,
  }))

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getUnapprovedStores(page))
  }, [dispatch, page, searchText])

  const columns = [
    {
      dataField: "storeName",
      text: "Store Name",
      sort: true,
    },
    {
      dataField: "dealerDetails.name",
      text: "Dealer Name",
      sort: true,
    },
    {
      dataField: "storeAddress.area",
      text: "Place",
      sort: true,
    },
    {
      dataField: "storeAddress.district",
      text: "District",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const storeData = map(unapprovedStores, item => ({
    ...item,

    activeData: (
      <>
        {/* <Switch  onChange={() => handleSwitchChange(item?._id)} checked={item?.isActive} /> */}
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item?._id)
            }}
            checked={item?.isActive}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={`/store/${item?._id}`} className="btn-light btn-sm">
          View Details
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: unapprovedStores?.length,
    custom: true,
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zunapprovedStores.filter(zstore =>
        Object.keys(zshop).some(key =>
          zstore[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const handleSwitchChange = (e, id) => {
    // TODO: API needed for status change
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={storeData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                // keyField={"id"}
                                key={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                // defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default QuarantinedStores
