import { get } from "helpers/api_helper"
import { take } from "lodash"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  getPromoterDashbordPageviewSuccess,
  getPromoterDashboardPageviewFail,
  getPromoterDashboardEarningsSuccess,
  getPromoterDashboardEaningsFail,
  getPromoterProductSuccess,
  getPromoterProductFail,
  getPromoterProductDetailsFail,
  getPromoterProductDetailsSuccess,
} from "./actions"
import {
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
  GET_PROMOTER_PRODUCT,
  GET_PROMOTER_PRODUCT_DETAILS,
} from "./actionTypes"

//Promoter Dashboard page view

function promoterdashboardPageviewApi(page) {
  return get(`/page-view/promoter/all?page=${page ? page : 1}`)
}
// Promoter Dashbord earnings

function promoterDashboardEarningsApi(page) {
  return get(`/earning/promoter/all?page=${page ? page : 1}`)
}

// promoter product

function promoterProductApi({ search, page }) {
  if (search) {
    return get(`/product/promoter/all?search=${search}`)
  } else {
    return get(`/product/promoter/all?page=${page ? page : 1}`)
  }
}

//promoter product details

function productDetailsApi(productId) {
  return get(`/product/promoter/single/${productId}`)
}

// promoter dashbord
// page view

function* promoterDashboardPageView({ payload }) {
  try {
    const res = yield call(promoterdashboardPageviewApi, payload)
    yield put(getPromoterDashbordPageviewSuccess(res))
  } catch (error) {
    yield put(getPromoterDashboardPageviewFail(error))
  }
}

// earnings

function* promoterDashboardEarnings({ payload }) {
  try {
    const response = yield call(promoterDashboardEarningsApi, payload)
    yield put(getPromoterDashboardEarningsSuccess(response))
  } catch (error) {
    yield put(getPromoterDashboardEaningsFail(error))
  }
}

// promoter product

function* promoterProduct({ payload }) {
  try {
    const response = yield call(promoterProductApi, payload)
    yield put(getPromoterProductSuccess(response))
  } catch (error) {
    yield put(getPromoterProductFail(error))
  }
}
// promoter product details

function* fetchPromoterProductDetails({ payload }) {
  try {
    const response = yield call(productDetailsApi, payload)
    yield put(getPromoterProductDetailsSuccess(response))
  } catch (error) {
    yield put(getPromoterProductDetailsFail(error))
  }
}

function* promoterSaga() {
  // promoter dashboard
  // page view
  yield takeEvery(
    PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
    promoterDashboardPageView
  )
  // earnings
  yield takeEvery(
    GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
    promoterDashboardEarnings
  )
  // promoter product
  yield takeEvery(GET_PROMOTER_PRODUCT, promoterProduct)

  // promoter product details
  yield takeEvery(GET_PROMOTER_PRODUCT_DETAILS, fetchPromoterProductDetails)
}
export default promoterSaga
