import React, { useCallback, useRef } from "react"
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import PropTypes from "prop-types"
import mapStyle from "./mapStyle"

import { MapSearch } from "./MapSearch"

const libraries = ["places"]

const mapContainerStyle = {
  width: "100%",
  height: "400px",
  borderRadius: "0 0 15px 15px",
}
const center = {
  lat: 11.041630014282479,
  lng: 76.08493600000001,
}

const options = {
  styles: mapStyle,
  disableDefaltUI: true,
  zoomConrol: true,
  maxZoom: 20,
}
const MyGoogleMap = ({
  MAP_PUBLIC_KEY,
  setMarkers,
  markers,
  notMark,
  height,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:
      MAP_PUBLIC_KEY || "AIzaSyAU4EsCpVhv88dQYuWU9enLuWLcQ3-Npo8",
    libraries,
  })

  const mapRef = useRef()

  const onMapLoad = useCallback(map => {
    mapRef.current = map
  }, [])

  const zoomTo = ({ lat, lng }) => {
    if (lat && lng) {
      mapRef.current?.panTo({ lat, lng })
      mapRef.current?.setZoom(14)
    }
  }

  if (loadError) return "Error loading map"
  if (!isLoaded)
    return (
      <div className="d-flex align-items-center justify-center">
        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        map is loading
      </div>
    )

  const handleMapMarker = e => {
    if (notMark !== false) {
      setMarkers(current => [
        {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
          time: new Date(),
        },
      ])
    }
  }

  return (
    <>
      {MAP_PUBLIC_KEY && (
        <div className="position-relative custom-border">
          {notMark !== false && (
            <MapSearch zoomTo={zoomTo} setMarkers={setMarkers} />
          )}
          <GoogleMap
            mapContainerStyle={{ ...mapContainerStyle, height: height || 200 }}
            zoom={14}
            center={center}
            options={options}
            onClick={e => handleMapMarker(e)}
            onLoad={onMapLoad}
          >
            {markers?.map(marker => (
              <Marker
                key={marker.time.toISOString()}
                position={{ lat: marker.lat, lng: marker.lng }}
              />
            ))}
          </GoogleMap>
        </div>
      )}
    </>
  )
}

export default MyGoogleMap

MyGoogleMap.propTypes = {
  MAP_PUBLIC_KEY: PropTypes.string,
  setMarkers: PropTypes.func,
  markers: PropTypes.any,
  notMark: PropTypes.bool,
  height: PropTypes.any,
}
