import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTag from "components/Common/Meta-tag"
import GrossRevenues from "./GrossRevenue/Details"

function Banner() {
  return (
    <>
      <MetaTag title={"Revenue"} />

      <div className="page-content position-relative">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Revenue" />
        <Container fluid>
          <div className="container-fluid">
            <GrossRevenues />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Banner
