import React from "react"
import PropTypes from "prop-types"
import { Collapse } from "reactstrap"
import { useSelector } from "react-redux"
import { Button, Card, CardBody } from "reactstrap"
import { Swiper, SwiperSlide } from "swiper/react"
import { useState } from "react"
import { Row, Col } from "reactstrap"

import ReactPlayer from "react-player"
import classnames from "classnames"
import { map } from "lodash"

import useCloudinary from "hooks/useCloudinaryImage"
import MyRating from "components/Common/Rating"
import AddSectionModal from "./AddSectionModal"

import "swiper/swiper.scss"

import { defaultImg } from "assets/images"
import { Markup } from "interweave"

const SectionUi = items => {
  const item = items?.item

  const { pageDetails, proLoading, section, pageTitleCheck, products } =
    useSelector(state => ({
      pageDetails: state.Pages.pageDetails,
      section: state.Pages.section,
      pageTitleCheck: state.Pages?.pageTitleCheck,
      products: state.Products.products,
      proLoading: state.Products.loading,
    }))

  const productDetails = pageDetails?.product

  const [tab, setTab] = useState("")

  const banners = section?.images

  const handlePreviewCount = maxCount => {
    if (banners?.length < maxCount) {
      return banners?.length
    } else return maxCount
  }

  const discountPercentage = prod => {
    if (prod?.price > prod?.offerPrice) {
      return Math.floor(
        (Math.abs(prod?.price - prod?.offerPrice) / prod?.price) * 100
      )
    } else return
  }

  return (
    <CardBody
      className={items.className}
      style={{
        background: "#f8f9fa",
        marginTop: "10px",
      }}
    >
      <Card
        className="mb-2 me-3 "
        style={{
          width: "fit-content",
          border: "1px solid #f3f3f3",
          boxShadow: "none",
          borderRadius: 0,
        }}
      >
        <CardBody className="p-2 px-3">
          <p className="m-0 p-0 d-flex align-items-center">
            Platform :&nbsp;
            <span>{item?.platform}</span>
          </p>
        </CardBody>
      </Card>
      {/* //highlight */}
      <div>
        {item?.layout === "highlight" ? (
          <>
            <div className="container pb-4 pt-3">
              <div className="mx-5 sm:mx-0">
                <div className="d-flex-center-center highlight">
                  <div
                    className="content"
                    style={{
                      textAlign: item?.textAlign,
                    }}
                  >
                    <h4>{item?.title}</h4>
                    <Markup content={item?.description} />
                    <div className="product-card-bottom mt-0">
                      <div className="product-price ">
                        <span
                          className="font-head-new"
                          style={{
                            margin: "0 5px 0 5px",
                            fontWeight: "500",
                          }}
                        >
                          {productDetails?.price}
                        </span>
                        <del
                          className="old-price-new m-0"
                          style={{
                            fontWeight: "500",
                          }}
                        >
                          {productDetails?.offerPrice}
                        </del>
                        &nbsp;
                        <div>
                          {item?.callToAction?.isRequired === true && (
                            <>
                              <Button color="danger" size="sm">
                                {item?.callToAction?.text ?? "View Details"}
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {item?.images && item?.images[0]?.url && (
                    <div className="image-wrapper">
                      <img
                        src={useCloudinary(
                          item?.images[0]?.url ?? "",
                          "w_1000"
                        )}
                        height={"100%"}
                        width={"100%"}
                        loading="lazy"
                        alt="highlight-mainimage"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {/* Short content */}
      {item?.layout === "shortContent" && (
        <>
          <div
            className="row p-4 px-0 py-4 d-flex justify-content-center align-items-center mt-4 flex-wrap mx-2 my-2"
            style={{
              backgroundColor: item?.bgColor,
              minHeight: "100px",
            }}
          >
            {map(item?.shortContents, (content, key) => (
              <div className="col-3 px-0 shortContents" key={key}>
                <div
                  style={{ display: "flex" }}
                  className="px-0 shortContentInner"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <img
                      src={useCloudinary(content?.image?.url ?? "", "w_300")}
                      className="me-3"
                      width={100}
                      height={100}
                      alt="short-content"
                      loading="lazy"
                      style={{
                        height: "80px",
                        width: "80px",
                        objectFit: "contain",
                      }}
                    />
                    <h6
                      style={{
                        color: item?.fgColor,
                        display: "flex",
                        alignItems: "center",
                        fontSize: 14,
                      }}
                    >
                      {content?.title?.length > 50
                        ? content?.title?.slice(0, 50) + "..."
                        : content?.title}
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {/* Wide content  */}
      {item?.layout === "wideContent" ? (
        <>
          <div
            className={`wideContent ${
              item?.images && item?.images[0]?.url && item?.imagePosition
            }`}
            style={{
              backgroundColor: item?.bgColor,
            }}
          >
            <div
              style={{ padding: 40 }}
              className={`content ${
                item?.images && item?.images[0]?.url && "withImg"
              }`}
            >
              <h4 style={{ color: item?.fgColor }}>{item?.title}</h4>

              <Markup className="pt-2" content={item?.description} />

              {item?.callToAction?.isRequired === true ? (
                <button className="btn" style={{ color: "#fff" }}>
                  {item?.callToAction?.text ?? ""}
                </button>
              ) : (
                ""
              )}
            </div>
            {item?.images?.length > 0 ? (
              <div className="contentImage">
                <img
                  src={useCloudinary(item?.images[0]?.url ?? "", "w_1000")}
                  height={"100%"}
                  width={"100%"}
                  alt="wide-content-image"
                  loading="lazy"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {/* Carousel  */}
      {item?.layout === "carousel" && (
        <>
          <div className="row mt-60 mb-60">
            <div className="col-12">
              <div className="row related-products position-relative">
                <Swiper
                  spaceBetween={10}
                  grid={{
                    rows: 2,
                  }}
                  slidesPerView={item?.carouselCount}
                  keyboard={{ enabled: true }}
                  direction="horizontal"
                  autoplay={true}
                  speed={600}
                  className=""
                  breakpoints={{
                    480: {
                      slidesPerView: handlePreviewCount(1),
                    },
                    640: {
                      slidesPerView: handlePreviewCount(2),
                    },
                    768: {
                      slidesPerView: handlePreviewCount(item?.carouselCount),
                    },
                    1024: {
                      slidesPerView: handlePreviewCount(item?.carouselCount),
                    },
                  }}
                >
                  {map(item?.images, (item, i) => (
                    <SwiperSlide key={i}>
                      <div className="banner-img mobileContainer wow animate__animated animate__fadeInUp w-100">
                        <span className="cursor-pointer">
                          <img
                            src={useCloudinary(item?.url ?? "", "w_1000")}
                            alt="babyday-bannerImg"
                            className="fit-cover h-100 w-100 object-start mobileBanner"
                            height={"100%"}
                            width={"100%"}
                            title="babyday-bannerImg"
                            loading="eager"
                          />
                        </span>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </>
      )}

      {/* image Grid  */}
      {item?.layout === "imageGrid" && (
        <div>
          <div className="image-grid">
            <Row>
              {map(item?.images, (img, key) => (
                <Col lg={item?.gridSize?.lg} sm={item?.gridSize?.sm} key={key}>
                  <div className="gridItem">
                    <img
                      src={useCloudinary(img?.url ?? "", "w_800")}
                      height={"80%"}
                      width={"80%"}
                      alt="grid-images"
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}

      {/* //  Video  */}
      {item?.layout === "video" && (
        <>
          <div className="video_banner py-3">
            <div className="react-player d-flex-center-center">
              <div className="react-player-video">
                <ReactPlayer
                  url={`https://www.youtube.com/watch?v=${item?.videoId}`}
                  controls={false}
                  light={true}
                  width={"540px"}
                  height={"250px"}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {/* // Product Varients  */}
      {item?.layout === "productVariant" && (
        <>
          <h2
            className="productVarienth2 mb-1
            
            "
            style={{ textAlign: item?.textAlign }}
          >
            {item?.title}
          </h2>
          <p className="text-center ">{item?.description}</p>
          <div className="productVarient">
            <Swiper
              spaceBetween={20}
              centeredSlides={false}
              freeMode={true}
              pagination={false}
              slidesPerView={3}
              initialSlide={1}
              direction="horizontal"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
            >
              <>
                {map(item?.productVariants, (product, key) => (
                  <SwiperSlide key={key}>
                    <div className="review-slide">
                      <div className="img-wrap mb-4 w-fit">
                        <img
                          src={
                            product?.images && product?.images[0]?.url
                              ? useCloudinary(
                                  product?.images && product?.images[0]?.url,
                                  "w_800"
                                )
                              : defaultImg
                          }
                          loading={"lazy"}
                          alt="google icon"
                        />
                      </div>
                      <div className="p-2 px-4" style={{ flex: 1 }}>
                        <h4 className="text-start pb-2 w-fit m-auto">
                          {product?.primaryLang?.name?.length >= 60
                            ? `${product?.primaryLang?.name?.slice(0, 60)}...`
                            : product?.primaryLang?.name}
                        </h4>
                        <p className="mb-3 text-start">
                          <span
                            style={{
                              fontSize: 17,
                              fontWeight: "bold",
                            }}
                          >
                            ₹{product?.offerPrice}
                          </span>{" "}
                          <del className="text-muted">₹{product?.price}</del>
                          &nbsp;
                          <span
                            style={{
                              color: "green",
                            }}
                          >
                            {discountPercentage(product)}%
                          </span>
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </>
            </Swiper>
          </div>
        </>
      )}

      {/* // testimonial */}
      {item?.layout === "testimonials" && (
        <>
          <div className="customerReview">
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              freeMode={true}
              pagination={false}
              slidesPerView={2.5}
              initialSlide={1}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2.3,
                },
                1200: {
                  slidesPerView: 2.5,
                },
              }}
            >
              <>
                {map(item?.testimonials, (ban, key) => (
                  <SwiperSlide key={key}>
                    <div className="review-slide">
                      <div className="d-flex flex-column align-items-center m-auto mb-4 justify-content-center w-fit">
                        {ban?.image ? (
                          <>
                            <img
                              className="rounded-circle mb-2"
                              src={useCloudinary(
                                ban?.image?.url ?? "",
                                "w_800"
                              )}
                              loading={"lazy"}
                              alt="google icon"
                              width={70}
                              height={70}
                            />
                          </>
                        ) : (
                          ""
                        )}
                        <MyRating
                          className="text-warning"
                          initialRating={ban?.rating}
                          quiet={true}
                          readonly={true}
                        />
                      </div>
                      <p className="mb-3 text-center">“{ban?.message}”</p>
                      <h4 className="text-center w-fit m-auto">{ban?.name}</h4>
                    </div>
                  </SwiperSlide>
                ))}
              </>
            </Swiper>
          </div>
        </>
      )}

      {/* // FAQ  */}
      {item?.layout === "faqData" && (
        <>
          <div className="container">
            <div className="px-2">
              <div className={"testimonials"}>
                <div className="mt-4 p-5 sm:p-0">
                  <h4>Frequently Asked Question</h4>
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    {map(item?.faqData, (item, key) => (
                      <div className="accordion-item" key={key}>
                        <h2 className="accordion-header" id="headingFlushOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              {
                                collapsed: tab !== key + 1,
                              }
                            )}
                            type="button"
                            onClick={() => setTab(key + 1)}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {item?.question}
                          </button>
                        </h2>

                        <Collapse
                          isOpen={tab === key + 1}
                          className="accordion-collapse"
                        >
                          <div className="accordion-body">
                            <div className="text-dark">{item?.answer}</div>
                          </div>
                        </Collapse>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* // background color */}
      <div className="mt-3">
        {item?.bgColor || item?.fgColor ? (
          <div className="d-flex align-items-center pt-3">
            {item?.bgColor && (
              <Card
                className="mb-2 me-1"
                style={{
                  width: "fit-content",
                  border: "1px solid #f3f3f3",
                  boxShadow: "none",
                  borderRadius: 0,
                }}
              >
                <CardBody className="p-2 px-3">
                  <p className="m-0 p-0 d-flex align-items-center">
                    Background Color :
                    <span
                      className="mx-2"
                      style={{
                        backgroundColor: item?.bgColor,
                        height: 15,
                        width: 15,
                      }}
                    ></span>{" "}
                    <span>{item?.bgColor}</span>
                  </p>
                </CardBody>
              </Card>
            )}
            {item?.fgColor && (
              <Card
                className="mb-2 me-1"
                style={{
                  width: "fit-content",
                  border: "1px solid #f3f3f3",
                  boxShadow: "none",
                  borderRadius: 0,
                }}
              >
                <CardBody className="p-2 px-3">
                  <p className="m-0 p-0 d-flex align-items-center">
                    Foreground Color :
                    <span
                      className="mx-2"
                      style={{
                        backgroundColor: item?.fgColor,
                        height: 15,
                        width: 15,
                      }}
                    ></span>{" "}
                    <span>{item?.fgColor}</span>
                  </p>
                </CardBody>
              </Card>
            )}
          </div>
        ) : (
          ""
        )}

        {/* //grid size */}
        {item?.gridSize?.lg || item?.gridSize?.sm ? (
          <div className="d-flex align-items-center mb-2">
            {item?.gridSize?.lg && (
              <Card
                className="mb-2 me-1"
                style={{
                  width: "fit-content",
                  border: "1px solid #f3f3f3",
                  boxShadow: "none",
                  borderRadius: 0,
                }}
              >
                <CardBody className="p-2 px-3">
                  <p className="m-0 p-0 d-flex align-items-center">
                    Large Screen :
                    <span className="mx-2">{item?.gridSize?.lg}</span>
                  </p>
                </CardBody>
              </Card>
            )}
            {item?.gridSize?.sm && (
              <Card
                className="mb-2 me-1"
                style={{
                  width: "fit-content",
                  border: "1px solid #f3f3f3",
                  boxShadow: "none",
                  borderRadius: 0,
                }}
              >
                <CardBody className="p-2 px-3">
                  <p className="m-0 p-0 d-flex align-items-center">
                    Small Screen :{" "}
                    <span className="mx-2">{item?.gridSize?.sm}</span>
                  </p>
                </CardBody>
              </Card>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </CardBody>
  )
}

export default SectionUi

AddSectionModal.propTypes = {
  items: PropTypes.any,
}
