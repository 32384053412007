import React, { useEffect } from "react"
import { Container, Row } from "reactstrap"
import MetaTags from "react-meta-tags"

//import component
import CardUser from "./CardUser"
import Settings from "./Settings"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentStoreDetails, getDashboardData } from "store/actions"

const MyStoreDetails = () => {
  const dispatch = useDispatch()
  const { currentStore } = useSelector(state => ({
    currentStore: state?.Stores?.currentStoreDetails,
  }))

  useEffect(() => {
    dispatch(getDashboardData())
    dispatch(getCurrentStoreDetails())
  }, [dispatch])

  console.log(currentStore)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboards" breadcrumbItem="My Store" />
          {currentStore?._id ? (
            <>
              {/* <Row>
                <CardUser />
              </Row> */}
              <Row>
                <Settings />
              </Row>
            </>
          ) : (
            ""
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MyStoreDetails
