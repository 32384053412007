export const sidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
    isStore: true,
  },
  {
    routeLink: "/stores",
    icon: "bx bx-store",
    title: "Store",
  },
  {
    routeLink: "/orders",
    icon: "bx bxs-shopping-bags",
    title: "Orders",
    isStore: true,
  },
  {
    routeLink: "/products",
    icon: "bx bxs-package",
    title: "Products",
    isStore: true,
  },

  {
    routeLink: "/stockUpdate",
    icon: "bx bx-store-alt",
    title: "Stock & Offer",
    isStore: true,
  },
  {
    routeLink: "/revenues",
    icon: "bx bxs-landmark",
    title: "Revenue",
    isStore: true,
  },
  {
    routeLink: "/performance/product",
    icon: "bx bxs-rocket",
    title: "Performance",
    isStore: true,
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Site settings",
    isSeparator: true,
  },
  {
    routeLink: "/classifications",
    icon: "bx bxs-collection",
    title: "Categories",
  },
  {
    routeLink: "/collections",
    icon: "bx bxs-extension",
    title: "Collections",
    isStore: true,
  },
  {
    routeLink: "/groups",
    icon: "fas fa-layer-group",
    title: "Groups",
    isStore: true,
  },
  {
    routeLink: "/pages/all",
    icon: "bx bx-food-menu",
    title: "Pages",
    isStore: true,
  },

  {
    routeLink: "/banners",
    icon: "bx bxs-image",
    title: "Banners",
  },

  {
    routeLink: "/coupons",
    icon: "bx bxs-coupon",
    title: "Coupon",
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Extras",
    isSeparator: true,
  },
  {
    routeLink: "/users",
    icon: "bx bxs-user",
    title: "Users",
  },

  {
    routeLink: "/reviews",
    icon: "bx bxs-award",
    title: "Reviews",
  },
  // {
  //   routeLink: "/blogs",
  //   icon: "bx bx-file",
  //   title: "Blogs",
  // },

  // {
  //   routeLink: "/#",
  //   icon: "bx bxs-user-voice",
  //   title: "Promoters",
  //   subTitles: [
  //     { title: "All Promoters", routeLink: "/promoters" },
  //     { title: "All Page Views", routeLink: "/all-page-view" },
  //     { title: "All Earnings", routeLink: "/all-earnings" },
  //     { title: "Formated Earnings", routeLink: "/formatted-earnings" },
  //   ],
  // },
  {
    routeLink: "/superadmin/settings",
    icon: "bx bxs-check-shield",
    title: "Admin Settings",
  },
]

export const promoterSideBarData = [
  { title: "Dashboard", routeLink: "/dashboard", icon: "bx bxs-home" },
  {
    title: " Page Views",
    routeLink: "/promoter-page-view",
    icon: "bx bx-show-alt",
  },
  {
    title: " Earnings",
    routeLink: "/promoter-earnigs",
    icon: "bx bx-dollar",
  },
  {
    title: "Products",
    routeLink: "/promoter/products",
    icon: "bx bxs-package",
  },
]
