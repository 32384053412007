import React, { useEffect, useState } from "react"
import { MdAdminPanelSettings } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { GoHome } from "react-icons/go"

import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  Media,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap"
import { FaStore } from "react-icons/fa"
import MyGoogleMap from "components/Gmap/googleMap"
import { getMapPublicKey } from "store/actions"

const Settings = props => {
  const dispatch = useDispatch()
  const { user, currentStore, mapKey, company } = useSelector(state => ({
    user: state.Login.user,
    mapKey: state.Settings.map,
    currentStore: state?.Stores?.currentStoreDetails,
    company: state.Settings.settings.company,
  }))

  const storeAdmin = currentStore?.storeAdminDetails || {}
  const storeAddress = currentStore?.storeAddress || {}
  const address = storeAdmin?.address || {}
  const shippingDetails = currentStore?.shippingDetails || {}

  const [markers, setMarkers] = useState({})
  const MAP_PUBLIC_KEY = mapKey?.key

  useEffect(() => {
    setMarkers(current => [
      {
        lat: currentStore?.location?.coordinates[0],
        lng: currentStore?.location?.coordinates[1],
        time: new Date(),
      },
    ])
  }, [currentStore])

  useEffect(() => {
    dispatch(getMapPublicKey())
  }, [dispatch])

  console.log(shippingDetails)

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody
            style={{
              height: "260px",
            }}
          >
            <div className="d-flex flex-wrap justify-content-between">
              <h5 className="card-title mb-3 me-2">
                {currentStore?.storeName}
              </h5>
              <UncontrolledDropdown className="ms-2">
                <DropdownToggle
                  className="btn btn-light btn-sm"
                  color="#eff2f7"
                  type="button"
                >
                  <i className="bx bxs-cog align-middle me-1"></i> Setting
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link
                    className="dropdown-item"
                    to={`/store/update/${currentStore?._id}`}
                  >
                    Edit Store
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <div className="d-flex flex-wrap">
              <div>
                <p className="text-muted mb-0">{currentStore?.phone}</p>
                <p className="text-muted mb-0">{currentStore?.email}</p>
              </div>
              <div
                className="ms-auto align-self-end"
                style={{
                  cursor: "pointer",
                }}
              >
                <UncontrolledTooltip target={"currentStoreIsClosed"}>
                  {currentStore?.isClosed ? "store is closed" : "store is open"}
                </UncontrolledTooltip>
                <FaStore
                  id="currentStoreIsClosed"
                  className={`display-4 text-${
                    currentStore?.isClosed ? "light" : "white"
                  } `}
                />
              </div>
              <div>
                <h6 className="mb-2 pt-1 d-flex align-items-center ">
                  <GoHome className="me-1" />
                  Address
                </h6>
                <p className="text-muted mb-0">{storeAddress?.addressLine1}</p>
                <p className="text-muted mb-0">{storeAddress?.addressLine2}</p>
                <span className="text-muted mb-0">
                  {storeAddress?.area},&nbsp;
                </span>
                <span className="text-muted mb-0">
                  {storeAddress?.country},&nbsp;
                </span>
                <span className="text-muted mb-0">
                  {storeAddress?.district},&nbsp;
                </span>
                <span className="text-muted mb-0">
                  {storeAddress?.landmark},&nbsp;
                </span>
                <span className="text-muted mb-0">
                  {storeAddress?.state},&nbsp;
                </span>
                <span className="text-muted mb-0">
                  {storeAddress?.pinCode}&nbsp;
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={4}>
        <Card>
          <CardBody
            style={{
              height: "260px",
            }}
          >
            <div className="media">
              <div className="me-3">
                <MdAdminPanelSettings className="avatar-sm text-success rounded-circle img-thumbnail" />
              </div>
              <div className="media-body">
                <Media>
                  <div className="media-body">
                    <div className="text-muted">
                      <h5 className="mb-1">{storeAdmin?.name}</h5>
                      <p className="mb-0">{storeAdmin?.phone}</p>
                    </div>
                  </div>
                </Media>

                <hr />
              </div>
            </div>
            <div>
              <h6 className="mb-2 d-flex align-items-center ">
                <GoHome className="me-1" />
                Address
              </h6>
              <div>
                <p className="text-muted mb-0">{address?.addressLine1}</p>
                <p className="text-muted mb-0">{address?.addressLine2}</p>
                <span className="text-muted mb-0">{address?.area},&nbsp;</span>
                <span className="text-muted mb-0">
                  {address?.country},&nbsp;
                </span>
                <span className="text-muted mb-0">
                  {address?.district},&nbsp;
                </span>
                <span className="text-muted mb-0">
                  {address?.landmark},&nbsp;
                </span>
                <span className="text-muted mb-0">{address?.state},&nbsp;</span>
                <span className="text-muted mb-0">
                  {address?.pinCode}&nbsp;
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col xl={4}>
        <Card>
          <CardBody
            style={{
              height: "260px",
            }}
          >
            <h5 className="card-title me-2"> Location</h5>
            <div className="d-flex mb-2 align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <p className="m-0 p-0 me-2 text-muted">
                  <span className="text-black">Lat</span> :{" "}
                  {currentStore?.location?.coordinates[0]}
                </p>
                <p className="m-0 p-0 text-muted">
                  <span className="text-black">Long</span> :{" "}
                  {currentStore?.location?.coordinates[1]}
                </p>
              </div>
            </div>
            <Row>
              <Col className="col-12 col-sm-12 col-xs-12 ">
                {markers && (
                  <MyGoogleMap
                    height={"168px"}
                    MAP_PUBLIC_KEY={MAP_PUBLIC_KEY}
                    notMark={false}
                    markers={markers}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col xl={4}>
        <Card>
          <CardBody className="p-4">
            <div className="text-start">
              <Row className="justify-content-start">
                <Col xl={10}>
                  <p className="text-black font-size-14 mb-2">
                    Shipping Details
                  </p>
                  <div>
                    <p className="text-muted mb-0">
                      Cod Charge :{" "}
                      <span className="text-black">
                        {company?.defaultCurrencySymbol}&nbsp;
                        {shippingDetails?.codCharge}
                      </span>
                    </p>
                    <p className="text-muted mb-0">
                      Default Shipping Charge :{" "}
                      <span className="text-black">
                        {company?.defaultCurrencySymbol}&nbsp;
                        {shippingDetails?.defaultShippingCharge}
                      </span>
                    </p>

                    <p className="text-muted mb-0">
                      Per Km Shipping Charge :{" "}
                      <span className="text-black">
                        {company?.defaultCurrencySymbol}&nbsp;
                        {shippingDetails?.perKmShippingCharge}
                      </span>
                    </p>

                    <p className="text-muted mb-0">
                      Delivery TimeLimit :{" "}
                      <span className="text-black">
                        {shippingDetails?.deliveryTimeLimit}
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={4}>
        <Card>
          <CardBody className="p-4">
            <div className="text-start">
              <Row className="justify-content-start">
                <Col xl={10}>
                  <p className="text-black font-size-14 mb-2">Boundary</p>
                  <div>
                    <p className="text-muted mb-0">
                      Primary Distance :{" "}
                      <span className="text-black">
                        {shippingDetails?.primaryBoundaryDistance} km
                      </span>
                    </p>
                    <p className="text-muted mb-0">
                      Primary ThresholdAmount :{" "}
                      <span className="text-black">
                        {company?.defaultCurrencySymbol}&nbsp;
                        {shippingDetails?.primaryBoundaryThresholdAmount}
                      </span>
                    </p>
                    <p className="text-muted mb-0">
                      Secondary Distance :{" "}
                      <span className="text-black">
                        {shippingDetails?.secondaryBoundaryDistance} km
                      </span>
                    </p>
                    <p className="text-muted mb-0">
                      Secondary ThresholdAmount :{" "}
                      <span className="text-black">
                        {company?.defaultCurrencySymbol}&nbsp;
                        {shippingDetails?.secondaryBoundaryThresholdAmount}
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Settings
