import React from "react"
import { useSelector } from "react-redux"
import AdminDashboard from "./AdminDashboard"
import PromoterDashboard from "./PromoterDashboard"

const Dashboard = () => {
  const { user } = useSelector(state => ({
    user: state.Login.user,
  }))
  const role = sessionStorage.getItem("role")

  if (user?.role === "admin" || role === "admin") {
    return <AdminDashboard />
  } else if (user?.role === "storeAdmin" || role === "storeAdmin") {
    return <AdminDashboard />
  }
  return <PromoterDashboard />
}

export default Dashboard
