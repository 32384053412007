import Select from "react-select"
import { Col, Container, Label, Row } from "reactstrap"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, FormGroup } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { WithContext as ReactTags } from "react-tag-input"

import { debounce } from "lodash"
import { useHistory } from "react-router-dom"
import MetaTag from "components/Common/Meta-tag"
import {
  checkPageTitle,
  createPages,
  getAllProducts,
  getStoreList,
} from "store/actions"
import UseImageUploader from "components/imageUploader/useImageUploader"
import { Notification } from "components/Common/Notification"
import useGetStore from "hooks/useGetStore"

const CratePages = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isStoreAdmin, currentStoreId } = useGetStore()

  const {
    loading,
    products,
    proLoading,
    pageTitleCheck,
    stores,
    StoreLoading,
  } = useSelector(state => ({
    loading: state.Group.loading,
    products: state.Products.products,
    proLoading: state.Products.loading,
    pageTitleCheck: state.Pages?.pageTitleCheck,
    stores: state.Stores.storeList,
    StoreLoading: state.Stores.loading,
  }))

  const [storeSearchText, setStoreSearchText] = useState("")
  const [selectedStore, setSelectedStore] = useState("All Store")
  const [finalStoreId, setFinalStoreId] = useState("")

  useEffect(() => {
    if (isStoreAdmin) {
      setFinalStoreId(currentStoreId)
    }
  }, [currentStoreId])

  //product///
  const [pageImage, setPageImage] = useState([])
  const [keywords, setKeywords] = useState([])
  const [tags, setTags] = useState([])
  const [checkPageTitles, setCheckPageTitles] = useState()

  const [productSelect, setproductSelect] = useState({})
  const [productSearch, setProductSearch] = useState()

  // console.log(productSelect)
  // console.log(productSearch)

  const debounceProductSearch = debounce(value => setProductSearch(value), 600)
  const handleProductEnters = textEntered => {
    debounceProductSearch({
      ...productSelect,
      searchText: textEntered,
    })
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.label,
      id: event.value,
      image: event.image,
      varients: event.varients,
    }
    setproductSelect(product)
  }

  const productOptions = [
    {
      options: (products?.length >= 1 ? products : [])?.map(
        (result, index) => ({
          key: index,
          label: `${result?.primaryLang?.name} ${
            result.hasVarients
              ? result?.varients?.length >= 1
                ? `(${result?.varients[0]?.value} ${result?.varients[0]?.unit})`
                : ""
              : ""
          }`,
          value: result?._id,
          image: result?.images[0]?.url,
        })
      ),
    },
  ]

  useEffect(() => {
    dispatch(
      getAllProducts(1, "all", "", productSearch?.searchText, finalStoreId)
    )
  }, [dispatch, productSearch?.searchText, finalStoreId])

  const handleValidSubmit = value => {
    const pageData = {
      ...value,
      store: finalStoreId,
      product: productSelect?.id,
      keywords: keywords,
      description: value.description,
      images: pageImage,
    }
    if (finalStoreId) {
      dispatch(createPages(pageData, history))
    } else {
      Notification({
        type: "info",
        message: "Please select a store first",
        title: "Required Field",
      })
    }
  }

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }

  const delimiters = [KeyCodes.comma, KeyCodes.enter]

  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }

  useEffect(() => {
    dispatch(getStoreList(storeSearchText))
  }, [dispatch, storeSearchText])

  const debounceStoreSearch = debounce(value => setStoreSearchText(value), 600)

  const handleEnters = textEntered => {
    debounceStoreSearch(textEntered)
  }

  function handlerStoreFinalValue(event) {
    setSelectedStore(event.label)
    setFinalStoreId(event.id)
  }

  const storeOptions = [
    {
      label: "All Store",
      id: "",
    },
    {
      options: stores?.map((result, index) => ({
        key: index,
        label: result?.storeName,
        id: result?._id,
      })),
    },
  ]

  return (
    <>
      <MetaTag title={"Create Pages"} />

      <div className="page-content">
        <Breadcrumbs title="Pages" breadcrumbItem="Create Page" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="8">
                  <Card>
                    <CardBody>
                      <Col className="col-12 d-flex flex-wrap">
                        {!isStoreAdmin && (
                          <div className="col-12 mb-3">
                            <Label>Store *</Label>
                            <Select
                              onInputChange={handleEnters}
                              value={selectedStore}
                              placeholder={selectedStore}
                              onChange={handlerStoreFinalValue}
                              options={storeOptions}
                              classNamePrefix="select-selection"
                              isLoading={StoreLoading}
                            />
                          </div>
                        )}
                        <div className="col-12 mb-3">
                          <AvField
                            name="title"
                            className=""
                            label="Page Title"
                            type="text"
                            onChange={e => {
                              setCheckPageTitles(e.target.value)
                              dispatch(checkPageTitle(e.target.value))
                            }}
                          />
                          {checkPageTitles && (
                            <>
                              {pageTitleCheck?.response === true ? (
                                <h6 className="pt-1" style={{ color: "green" }}>
                                  {" "}
                                  Available
                                </h6>
                              ) : (
                                <h6 className="pt-1" style={{ color: "red" }}>
                                  Not Available{" "}
                                </h6>
                              )}
                            </>
                          )}
                        </div>{" "}
                        <div className="col-12 mb-3">
                          <AvField
                            style={{ height: 100 }}
                            name="description"
                            className=""
                            label="Description"
                            type="textarea"
                          />
                        </div>
                        <div className="col-12 mb-3">
                          <CardTitle className="h4 mb-2 text_deals_green ">
                            Select Product
                          </CardTitle>
                          <FormGroup className="mb-3">
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <Select
                                onInputChange={handleProductEnters}
                                value={productSelect?.title}
                                placeholder={productSelect?.title}
                                onChange={handlerProductFinalValue}
                                options={productOptions}
                                classNamePrefix="select2-selection"
                                isLoading={proLoading}
                              />
                            </div>
                          </FormGroup>
                        </div>
                        <div className=" col-12 pb-3">
                          <label>Keywords</label>
                          <ReactTags
                            tags={tags}
                            placeholder="Type TEXT and enter"
                            // suggestions={suggestions}
                            delimiters={delimiters}
                            handleDelete={handleDeleteTag}
                            handleAddition={handleAddition}
                            handleDrag={handleDrag}
                            handleTagClick={handleTagClick}
                            // inputFieldPosition="inline"
                            autocomplete
                            allowUnique
                            inline
                          />
                        </div>
                        <div className=" col-12 pb-2">
                          <UseImageUploader
                            imageSize={4}
                            imagesPreview={pageImage}
                            setImagesPreview={setPageImage}
                            uploadUrl={"/pages"}
                          />
                        </div>
                      </Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="col-12 btn btn-success save-customer"
                          disabled={!finalStoreId}
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Create Page
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CratePages
