import {
  CHECK_PROMOTERID,
  CHECK_PROMOTERID_FAIL,
  CHECK_PROMOTERID_SUCCESS,
  CREATE_PROMOTER,
  CREATE_PROMOTER_FAIL,
  CREATE_PROMOTER_SUCCESS,
  DELETE_PROMOTER,
  DELETE_PROMOTER_FAIL,
  DELETE_PROMOTER_SUCCESS,
  GET_PROMOTERS,
  GET_PROMOTERS_SUCCESS,
  GET_PROMOTERS_FAIL,
  GET_PROMOTER_DETAILS,
  GET_PROMOTER_DETAILS_FAIL,
  UPDATE_PROMOTER,
  UPDATE_PROMOTER_FAIL,
  UPDATE_PROMOTER_SUCCESS,
  GET_PROMOTER_DETAILS_SUCCESS,
  GET_PAGE_VIEW,
  GET_PAGE_VIEW_SUCCESS,
  GET_PAGE_VIEW_DETAILS,
  GET_PAGE_VIEW_FAIL,
  GET_PAGE_VIEW_DETAILS_FAIL,
  GET_PAGE_VIEW_DETAILS_SUCCESS,
  GET_EARINIG_DETAILS,
  GET_EARINIG_DETAILS_FAIL,
  GET_EARINIG_DETAILS_SUCCESS,
  GET_EARNING,
  GET_EARNING_FAIL,
  GET_EARNING_SUCCESS,
  GET_ALL_FORMATTED_EARNINGS_SUCCESS,
  GET_ALL_FORMATTED_EARNINGS_FAIL,
  GET_ALL_FORMATTED_EARNINGS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_FAIL,
  GET_USER_DETAILS_SUCCESS,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS,
  GET_PROMOTER_PRODUCT,
  GET_PROMOTER_PRODUCT_FAIL,
  GET_PROMOTER_PRODUCT_SUCCESS,
  GET_PROMOTER_PRODUCT_DETAILS,
  GET_PROMOTER_PRODUCT_DETAILS_FAIL,
  GET_PROMOTER_PRODUCT_DETAILS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  // promoters: [],
  // promotersList: {},
  // promoterDetails: {},
  // promoterLoad: false,
  // promoterIdCheck: false,

  // // page view
  // pageView: {},
  // pageViewLoading: false,
  // pageViewDetails: {},

  // // earning
  // earningLoading: false,
  // earningDetails: {},
  // earnings: {},
  // formattedEarnings: {},

  //  PROMOTER DASHBOARD
  //page view
  dashboardPageView: {},
  dashboardLoading: false,
  // earnings
  dashboardEarnings: {},
  // product
  promoterProduct: {},
  productLoading: false,
  // product details
  promoterProductDetails: {},
  productDetailsLoading: false,
}

const Promoter = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case GET_PROMOTERS:
    // case GET_PROMOTER_DETAILS:
    // case CHECK_PROMOTERID:
    case GET_PROMOTER_PRODUCT_DETAILS:
      return {
        ...state,
        loading: true,
      }

    // case DELETE_PROMOTER:
    // case CREATE_PROMOTER:
    // case UPDATE_PROMOTER:
    // case GET_PAGE_VIEW:
    // case GET_PAGE_VIEW_DETAILS:
    // case GET_EARINIG_DETAILS:
    // case GET_EARNING:
    // case GET_ALL_FORMATTED_EARNINGS:
    case PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS:
    case GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS:
    case GET_PROMOTER_PRODUCT:
      return {
        ...state,
        // promoterLoad: true,
        // pageViewLoading: true,
        // earningLoading: true,
        userLoading: true,
        dashboardLoading: true,
        productLoading: true,
        productDetailsLoading: true,
      }
    // case GET_PROMOTERS_FAIL:
    // case GET_PROMOTER_DETAILS_FAIL:
    // case CREATE_PROMOTER_FAIL:
    // case UPDATE_PROMOTER_FAIL:
    // case DELETE_PROMOTER_FAIL:
    // case CHECK_PROMOTERID_FAIL:
    // case GET_PAGE_VIEW_FAIL:
    // case GET_PAGE_VIEW_DETAILS_FAIL:
    // case GET_EARINIG_DETAILS_FAIL:
    // case GET_EARNING_FAIL:
    // case GET_ALL_FORMATTED_EARNINGS_FAIL:
    case PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_FAIL:
    case GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_FAIL:
    case GET_PROMOTER_PRODUCT_FAIL:
    case GET_PROMOTER_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        earningLoading: false,
        userLoading: false,
        dashboardLoading: false,
        productLoading: false,
        productDetailsLoading: false,
      }
    // case GET_PROMOTERS_SUCCESS:
    //   return {
    //     ...state,
    //     promotersList: action.payload,
    //     loading: false,
    //   }
    // case GET_PROMOTER_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     promoterDetails: action.payload,
    //     promoterLoad: false,
    //   }
    // case CREATE_PROMOTER_SUCCESS:
    //   return {
    //     ...state,
    //     promoterDetails: action.payload,
    //     promoterLoad: false,
    //   }
    // case UPDATE_PROMOTER_SUCCESS:
    //   return {
    //     ...state,
    //     promoterDetails: action.payload,
    //     promoterLoad: false,
    //   }
    // case DELETE_PROMOTER_SUCCESS:
    //   return {
    //     ...state,
    //     promoters: action.payload,
    //     promoterLoad: false,
    //   }
    // case CHECK_PROMOTERID_SUCCESS:
    //   return {
    //     ...state,
    //     promoterIdCheck: action.payload,
    //     loading: false,
    //   }
    // case GET_PAGE_VIEW_SUCCESS:
    //   return {
    //     ...state,
    //     pageView: action.payload,
    //     pageViewLoading: false,
    //   }
    // case GET_PAGE_VIEW_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     pageViewDetails: action.payload,
    //     pageViewLoading: false,
    //   }
    // // All Earnings Details

    // case GET_EARINIG_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     earningDetails: action.payload,
    //     earningLoading: false,
    //   }
    // // All Earnings
    // case GET_EARNING_SUCCESS:
    //   return {
    //     ...state,
    //     earnings: action.payload,
    //     earningLoading: false,
    //   }
    // case GET_ALL_FORMATTED_EARNINGS_SUCCESS:
    //   return {
    //     ...state,
    //     formattedEarnings: action.payload,
    //     earningLoading: false,
    //   }

    // Promoter dasboard
    // page view

    case PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS_SUCCESS:
      return {
        ...state,
        dashboardPageView: action.payload,
        dashboardLoading: false,
      }
    // earnings

    case GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS_SUCCESS:
      return {
        ...state,
        dashboardEarnings: action.payload,
        dashboardLoading: false,
      }
    // product

    case GET_PROMOTER_PRODUCT_SUCCESS:
      return {
        ...state,
        promoterProduct: action.payload,
        productLoading: false,
      }

    // promoter product details

    case GET_PROMOTER_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetailsLoading: false,
        promoterProductDetails: action.payload,
      }

    default:
      return state
  }
}

export default Promoter
