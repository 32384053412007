import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_STORES,
  GET_STORE_LIST,
  GET_UNAPPROVED_STORES,
  GET_BANNED_STORES,
  CHANGE_STORE_STATUS,
  GET_STORE_DETAILS,
  CREATE_STORE,
  UPDATE_STORE,
  DELETE_STORE,
  GET_CURRENT_STORE_DETAILS,
} from "./actionTypes"
import {
  getStoresSuccess,
  getStoresFail,
  getStoreListSuccess,
  getStoreListFail,
  getUnapprovedStoresSuccess,
  getUnapprovedStoresFail,
  getBannedStoresSuccess,
  getBannedStoresFail,
  changeStoreStatusSuccess,
  changeStoreStatusFail,
  getStoreDetailsSuccess,
  getStoreDetailsFail,
  createStoreFail,
  createStoreSuccess,
  updateStoreSuccess,
  updateStoreFail,
  deleteStoreSuccess,
  deleteStoreFail,
  getCurrentStoreDetailsFail,
  getCurrentStoreDetailsSuccess,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

const role = sessionStorage?.getItem("role")

function getCurrentStoreDetailsApi() {
  return get(`/store`)
}

function getStoresAPi({ page }) {
  if (role && role === "admin") {
    return get(`/store/all?page=${page ? page : 1}&limit=10`)
  }
  return
}

const getStoreListAPi = searchText => {
  if (role && role === "admin") {
    return get(`/store/list?search=${searchText ? searchText : ""}`)
  }
  return
}

function getUnapprovedStoresAPi({ page }) {
  return get(`/store/unapproved/all?page=${page ? page : 1}&limit=10`)
}

function getBannedStoresAPi({ page }) {
  return get(`/store/banned/all?page=${page ? page : 1}&limit=10`)
}

const changeStoreStatusApi = ({ history }) => {
  return post(`/store/status`, history)
}

const getStoreDetailsAPi = storeId => {
  return get(`/store/${storeId}`)
}

const createStoreApi = ({ store }) => {
  return post("/store/new", store)
}

const updateStoreApi = ({ store, storeId }) => {
  return ApiPut(`/store/${storeId}`, store)
}

const deleteStoreApi = storeId => {
  return del(`/store/${storeId}`)
}

function* fetchStores({ payload }) {
  try {
    const response = yield call(getStoresAPi, payload)
    yield put(getStoresSuccess(response))
  } catch (error) {
    yield put(getStoresFail(error))
  }
}

function* fetchCurrentStoreDetails({ payload }) {
  try {
    const response = yield call(getCurrentStoreDetailsApi, payload)
    if (response?._id) {
      sessionStorage.setItem("storeId", response?._id)
      sessionStorage.setItem("store", response?.storeName)
    }
    yield put(getCurrentStoreDetailsSuccess(response))
  } catch (error) {
    yield put(getCurrentStoreDetailsFail(error))
  }
}

function* fetchStoreList({ payload }) {
  try {
    const response = yield call(getStoreListAPi, payload)
    yield put(getStoreListSuccess(response))
  } catch (error) {
    yield put(getStoreListFail(error))
  }
}

function* fetchUnapprovedStores({ payload }) {
  try {
    const response = yield call(getUnapprovedStoresAPi, payload)
    yield put(getUnapprovedStoresSuccess(response))
  } catch (error) {
    yield put(getUnapprovedStoresFail(error))
  }
}

function* fetchBannedStores({ payload }) {
  try {
    const response = yield call(getBannedStoresAPi, payload)
    yield put(getBannedStoresSuccess(response))
  } catch (error) {
    yield put(getBannedStoresFail(error))
  }
}

function* onChangeStoreStatus({ payload }) {
  try {
    const response = yield call(changeStoreStatusApi, payload)
    yield put(changeStoreStatusSuccess(response))
    Notification({
      type: "success",
      message: "Store Status Changed Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(changeStoreStatusFail(error))
  }
}

function* fetchStoreDetail({ payload: storeId }) {
  try {
    const response = yield call(getStoreDetailsAPi, storeId)
    yield put(getStoreDetailsSuccess(response))
  } catch (error) {
    yield put(getStoreDetailsFail(error))
  }
}

function* onCreateStore({ payload }) {
  try {
    const response = yield call(createStoreApi, payload)
    payload.history.push("/stores")
    yield put(createStoreSuccess(response))
    Notification({
      type: "success",
      message: "Store Created Successfully!",
      title: "",
    })
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }

    yield put(createStoreFail(error))
  }
}

function* onUpdateStore({ payload }) {
  try {
    const response = yield call(updateStoreApi, payload)
    if (payload?.role === "storeAdmin") {
      payload.history.push("/my-store")
    } else {
      payload.history.push("/stores")
    }
    yield put(updateStoreSuccess(response))
    Notification({
      type: "success",
      message: "Store Updated Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(updateStoreFail(error))
  }
}

function* onDeleteStore({ storeId, history }) {
  try {
    const response = yield call(deleteStoreApi, storeId)
    history.push("/stores")
    yield put(deleteStoreSuccess(response))
    Notification({
      type: "error",
      message: "Store Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(deleteStoreFail(error))
  }
}

function* storeSaga() {
  yield takeEvery(GET_CURRENT_STORE_DETAILS, fetchCurrentStoreDetails)
  yield takeEvery(GET_STORES, fetchStores)
  yield takeEvery(GET_STORE_LIST, fetchStoreList)
  yield takeEvery(GET_UNAPPROVED_STORES, fetchUnapprovedStores)
  yield takeEvery(GET_BANNED_STORES, fetchBannedStores)
  yield takeEvery(CHANGE_STORE_STATUS, onChangeStoreStatus)
  yield takeEvery(GET_STORE_DETAILS, fetchStoreDetail)
  yield takeEvery(CREATE_STORE, onCreateStore)
  yield takeEvery(UPDATE_STORE, onUpdateStore)
  yield takeEvery(DELETE_STORE, onDeleteStore)
}

export default storeSaga
