import React, { useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Media, Spinner } from "reactstrap"
import MetaTag from "components/Common/Meta-tag"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FaMailBulk } from "react-icons/fa"
import {
  getPromoterDashboardData,
  getPromoterProfileDetails,
} from "store/actions"

function PromoterDashboard() {
  const dispatch = useDispatch()
  const { loading, data, profile } = useSelector(state => ({
    loading: state.Dashboard.promoterDashboardLoading,
    data: state.Dashboard?.promoterDashboardData,
    profile: state?.Dashboard?.promoterProfile,
  }))

  useEffect(() => {
    dispatch(getPromoterDashboardData())
    dispatch(getPromoterProfileDetails())
  }, [dispatch])

  return (
    <React.Fragment>
      <div>
        <MetaTag title="Promoter Dashboard" />

        <div className="page-content">
          <Container fluid>
            <div className="container-fluid">
              {loading ? (
                <Spinner color="secondary" className="d-block m-auto" />
              ) : (
                <>
                  <Row>
                    <Col md="4">
                      <Card
                        className=""
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        }}
                      >
                        <CardBody className="pt-0" style={{ height: "96px" }}>
                          <Row>
                            <Col className="mt-3">
                              <h5 className="font-size-15 text-muted">
                                {profile?.name}
                              </h5>
                              <p className="text-muted mb-0 text-truncate">
                                <FaMailBulk className="text-success" /> &nbsp;
                                {profile?.email}
                              </p>{" "}
                              <p className="text-muted mb-0 text-truncate">
                                <i className="bx bx-phone-call text-success" />
                                &nbsp;
                                {profile?.phone}
                              </p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="4">
                      <Card
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        }}
                      >
                        <CardBody className="w-100">
                          <Link to={"/promoter-earnigs"}>
                            <Media>
                              <Media body>
                                <p className="text-muted ">
                                  Total Earning Amount
                                </p>
                                <h4 className="mb-0">
                                  ₹{data?.totalEarningsAmount}
                                </h4>
                              </Media>
                              <div className="avatar-sm rounded-circle bg-primary bg-soft align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-light text-primary">
                                  <i
                                    className={
                                      "bx " + "bx bx-dollar" + " font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </Media>
                          </Link>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="4">
                      <Card
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        }}
                      >
                        <CardBody>
                          <Link to={"/promoter-earnigs"}>
                            {" "}
                            <Media>
                              <Media body>
                                <p className="text-muted ">
                                  Total Earning Count
                                </p>
                                <h4 className="mb-0">
                                  {data?.totalEarningsCount}{" "}
                                </h4>
                              </Media>
                              <div className="avatar-sm rounded-circle bg-primary bg-soft align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-light text-primary">
                                  <i
                                    className={
                                      "bx " + "bx bx-group" + " font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </Media>
                          </Link>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Card
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        }}
                      >
                        <CardBody>
                          <Link to={"/promoter-page-view"}>
                            {" "}
                            <Media>
                              <Media body>
                                <p className="text-muted ">
                                  Total Pageviews Count
                                </p>
                                <h4 className="mb-0">
                                  {data?.totalPageViewsCount}
                                </h4>
                              </Media>
                              <div className="avatar-sm rounded-circle bg-primary bg-soft align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-light text-primary">
                                  <i
                                    className={
                                      "bx " + "bx bx-group" + " font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </Media>
                          </Link>
                        </CardBody>
                      </Card>
                    </Col>{" "}
                    <Col md="4">
                      <Card
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        }}
                      >
                        <CardBody>
                          {" "}
                          <Media>
                            <Media body>
                              <p className="text-muted ">
                                Number of Page views today
                              </p>
                              <h4 className="mb-0">
                                {data?.todayPageViewsCount}
                              </h4>
                            </Media>
                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-light text-primary">
                                <i
                                  className={
                                    "bx " + "bx bx-group" + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>{" "}
                    <Col md="4">
                      <Card
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        }}
                      >
                        <CardBody>
                          {" "}
                          <Media>
                            <Media body>
                              <p className="text-muted ">
                                Number of Earnings Today
                              </p>
                              <h4 className="mb-0">
                                {data?.todayEarningsCount}
                              </h4>
                            </Media>
                            <div className="avatar-sm rounded-circle bg-primary bg-soft align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-light text-primary">
                                <i
                                  className={
                                    "bx " + "bx bx-group" + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PromoterDashboard
