import moment from "moment"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Badge, Card, CardBody } from "reactstrap"
import { getUserDetails } from "store/actions"

function UserAccount() {
  const dispatch = useDispatch()
  const { orderDetails, userDetails } = useSelector(state => ({
    orderDetails: state.Orders.orderDetails,
    userDetails: state.Users.userDetails,
  }))

  useEffect(() => {
    dispatch(getUserDetails(orderDetails?.user))
  }, [orderDetails?.user])

  return (
    <Card>
      <CardBody>
        <div className="mb-1 d-flex align-items-start justify-content-between">
          {/* <p className="mb-1">User Account</p> */}

          <Badge className={"font-size-12 badge-soft-danger"} color="" pill>
            User
          </Badge>
        </div>
        <h6 className="mb-2 mx-1">{userDetails?.name}</h6>
        <p className="mb-0 d-flex align-items-center">
          <i className="bx bx-phone-call text-success font-size-16 mx-1"></i>
          {userDetails?.phone}
        </p>
        <p className="mb-0 mx-1 d-flex align-items-center text-warning">
          Joined on&nbsp;
          {moment(userDetails?.createdAt).format("ll")}
        </p>
      </CardBody>
    </Card>
  )
}

export default UserAccount
