import MetaTag from "components/Common/Meta-tag"
import { LanguageSwitch } from "hooks/LanguageSwitch"
import { map, range } from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import { Card, Col, Container, Label, Row, Spinner } from "reactstrap"
import { getPromoterDashbordPageview } from "store/actions"
import MyPagination from "components/Common/MyPagination"
import noImage from "assets/images/Defualt/noimage3.png"

function PageViews() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)

  const { loading, dashboardPageviews } = useSelector(state => ({
    loading: state?.Promoter?.dashboardLoading,
    dashboardPageviews: state?.Promoter?.dashboardPageView,
  }))
  const total = dashboardPageviews?.total ?? 0

  useEffect(() => {
    dispatch(getPromoterDashbordPageview(page))
  }, [dispatch, page])

  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const PreColumns = [
    {
      dataField: "imageData",
      text: "Image",
    },
    {
      dataField: "productName",
      text: "Name",
    },
    {
      dataField: "createdAt",
      text: "Date",
    },
  ]
  const columns = LanguageSwitch()?.isMult
    ? PreColumns
    : PreColumns?.filter(i => i.text !== "Secondary Name")
  const pageViewdata = map(dashboardPageviews?.pageViews, (item, index) => ({
    ...item,
    imageData: (
      <img
        className="rounded-circle header-profile-user"
        style={{ width: "70px", height: "70px" }}
        src={item?.product?.images ? item?.product?.images[0]?.url : noImage}
      />
    ),
    productName: (
      <span>
        {item?.product?.primaryLang?.name ? (
          item?.product?.primaryLang?.name
        ) : (
          <p className="text-warning"> no product</p>
        )}
      </span>
    ),
    createdAt: (
      <h6 className="text-dark">
        {moment(item?.createdAt).format("l h:mm:a")}
      </h6>
    ),
  }))

  return (
    <div>
      <MetaTag title={"page view"} />
      <div className="page-content">
        <Container fluid>
          <div className="container-fluid">
            <Card className="shadow p-3 mb-5 bg-white rounded">
              {/* <Label className="text-muted">Page Views</Label> */}
              {dashboardPageviews?.total === 0 ? (
                <p className="text-warning">No page Views to see</p>
              ) : (
                <>
                  <Col>
                    <ToolkitProvider
                      keyField="_id"
                      columns={columns}
                      data={pageViewdata}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          {loading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : (
                            <>
                              <Row>
                                <Col>
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      // keyField={"id"}
                                      key={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      // defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      // onTableChange={handleTableChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* <MyPagination
                                pages={pages}
                                clcickedPage={page}
                                onNunClick={item => setPage(item)}
                                onNextClick={() => setPage(page + 1)}
                                onPrevClick={() => setPage(page - 1)}
                                apiPage={pageSend}
                              /> */}

                              <MyPagination
                                totalPages={pages}
                                page={page}
                                setPage={setPage}
                                apiPage={pageSend}
                              />
                            </>
                          )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </Col>
                </>
              )}
            </Card>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PageViews
