import React, { useEffect, useState } from "react"
import { Badge, Button, Card, CardBody, Col, Row } from "reactstrap"
import { MdEmail } from "react-icons/md"
import { Link, useHistory, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { deletePromoter, getPromoterDetails } from "store/actions"
import moment from "moment"
import DeleteModal from "components/Common/DeleteModal"

function Profile() {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const { promoterDetails } = useSelector(state => ({
    promoterDetails: state?.Users?.promoterDetails,
  }))
  useEffect(() => {
    dispatch(getPromoterDetails(params?.id))
  }, [])
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })

  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deletePromoter(isOpen?.id, history))
  }

  return (
    <div>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Card className="shadow p-3 mb-5 bg-white rounded ">
        <Row>
          <Col sm={5}>
            <h5>{promoterDetails?.name}</h5>

            <h6 className="d-flex align-items-center">
              <i
                style={{ color: "green" }}
                className="bx bx-phone-call font-size-18 "
              />
              &nbsp;
              {promoterDetails?.phone}
            </h6>
            <h6 className="d-flex align-items-center">
              <MdEmail style={{ color: "green" }} className="font-size-18" />
              &nbsp;
              {promoterDetails?.email}
            </h6>

            <Row>
              <Col className="text-dark" sm={12}>
                {" "}
                Joined In{" "}
                <span className="text-success">
                  {moment(promoterDetails?.createdAt).format("l h:mm A")}
                </span>
              </Col>
            </Row>
          </Col>
          <Col className="pt-3" sm={4}>
            <Row>
              <Col
                className="d-flex align-items-center justify-content-center"
                md={4}
              >
                <h6>Referral ID </h6>
              </Col>
              :
              <Col md={4}>
                <h6> {promoterDetails?.ref_id}</h6>
              </Col>
            </Row>
            <Row>
              <Col
                className="d-flex align-items-center justify-content-center"
                md={4}
              >
                <h6>Promoter %</h6>
              </Col>
              :
              <Col md={4}>
                <h6>
                  {promoterDetails?.promoterPercentage
                    ? promoterDetails?.promoterPercentage
                    : "0"}{" "}
                  %
                </h6>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="d-flex align-items-end justify-content-end py-2">
          <Button
            className="px-3"
            color="white"
            onClick={() => handleDelete(promoterDetails?._id)}
            type="button"
          >
            <i
              title="remove promoter"
              onClick={() => handleDelete(promoterDetails?._id)}
              className="fas fa-trash font-size-15"
            />
          </Button>
          <Link to={`/promoter/update/${params?.id}`}>
            <Button type="button" className="px-5 mx-1 me-2" color="info">
              Edit
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  )
}

export default Profile
