/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS"
export const API_FAIL = "API_FAIL"
export const GET_CHARTS_DATA = "GET_CHARTS_DATA"

/* Get Dashboard Count data */
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA"
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS"
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL"

// promoter dashboard profile

export const GET_PROMOTER_PROFILE_DETAILS = "GET_PROMOTER_PROFILE_DETAILS"
export const GET_PROMOTER_PROFILE_DETAILS_SUCCESS =
  "GET_PROMOTER_PROFILE_DETAILS_SUCCESS"
export const GET_PROMOTER_PROFILE_DETAILS_FAIL =
  "GET_PROMOTER_PROFILE_DETAILS_FAIL"

// Promoter dashbord data

export const GET_PROMOTER_DASHBOARD_DATA = "GET_PROMOTER_DASHBOARD_DATA"
export const GET_PROMOTER_DASHBOARD_DATA_SUCCESS =
  "GET_PROMOTER_DASHBOARD_DATA_SUCCESS"
export const GET_PROMOTER_DASHBOARD_DATA_FAIL =
  "GET_PROMOTER_DASHBOARD_DATA_FAIL"
