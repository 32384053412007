import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  NavLink,
  TabContent,
  TabPane,
  NavItem,
  Button,
} from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import classnames from "classnames"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"

//actions
import { createStore, getMapPublicKey } from "store/actions"
import { getUsers } from "store/users/actions"
import MetaTag from "components/Common/Meta-tag"
import MyGoogleMap from "components/Gmap/googleMap"

const CreateStore = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, createStoreError, mapKey, settings } = useSelector(
    state => ({
      createStoreError: state.Stores.createStoreError,
      loading: state.Stores.loading,
      mapKey: state.Settings.map,
      settings: state.Settings.settings,
    })
  )

  const shipping = settings?.shipping

  const [commonTiming, setCommonTiming] = useState([
    {
      from: "",
      to: "",
    },
  ])

  const [dayWiseTiming, setDayWiseTiming] = useState([
    {
      day: "MONDAY",
      isClosed: false,
      timing: [
        {
          from: "",
          to: "",
        },
      ],
    },
  ])

  const [isOpenEveryDay, setIsOpenEveryDay] = useState(false)
  const [isOpen24hrs, setIsOpen24hrs] = useState(false)
  const [isCommonTiming, setIsCommonTiming] = useState(false)

  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const [mapValues, setMapValues] = useState([11.0408, 76.0849])
  const [markers, setMarkers] = useState([])

  function handleValidSubmit(values) {
    const createStoreData = {
      storeAdminDetails: values.storeAdminDetails,
      storeNo: values.storeNo,
      storeName: values.storeName,
      phone: values.phone,
      email: values.email,
      storeAddress: values.storeAddress,
      location: {
        coordinates: [
          values.location?.coordinates[0],
          values.location?.coordinates[1],
        ],
      },
      shippingDetails: {
        primaryBoundaryDistance: parseInt(values?.primaryBoundaryDistance),
        primaryBoundaryThresholdAmount: parseInt(
          values?.primaryBoundaryThresholdAmount
        ),
        secondaryBoundaryDistance: parseInt(values?.secondaryBoundaryDistance),
        secondaryBoundaryThresholdAmount: parseInt(
          values?.secondaryBoundaryThresholdAmount
        ),
        defaultShippingCharge: parseInt(values?.defaultShippingCharge),
        deliveryTimeLimit: values?.deliveryTimeLimit,
        perKmShippingCharge: parseInt(values?.perKmShippingCharge),
        codCharge: parseInt(values?.codCharge),
      },
      // workingHours: {
      //   commonTiming: commonTiming,
      //   dayWiseTiming: dayWiseTiming,
      //   isCommonTiming: isCommonTiming,
      //   isOpen24Hrs: isOpen24hrs,
      //   isOpenEveryDay: isOpenEveryDay,
      // },
    }
    console.log(createStoreData)
    dispatch(createStore(createStoreData, history))
  }

  const pointVals = [mapValues]
  const pointMode = {
    banner: false,
    control: {
      values: pointVals,
      onClick: point => setMapValues(point),
    },
  }

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  useEffect(() => {
    dispatch(getMapPublicKey())
  }, [dispatch])

  const MAP_PUBLIC_KEY = mapKey?.key

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setMarkers(current => [
          {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            time: new Date(),
          },
        ])
      },
      () => null
    )
  }, [])

  const days = [
    "MONDAY",
    "TUESDAY",
    "WEDNESSDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ]

  return (
    <>
      <MetaTag title={"Create Store"} />

      <div className="page-content">
        <Breadcrumbs title="Stores" breadcrumbItem="Create Store" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(v)
                }}
                className="sm:p-0"
              >
                <Col lg={12} md={12}>
                  {/* {createShopError && (
                    <Alert color="danger" value={createShopError} />
                  )} */}

                  <Row>
                    <Card>
                      <CardBody>
                        <div className="wizard clearfix">
                          <div className="steps clearfix">
                            <ul>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    current: activeTab === 1,
                                  })}
                                  onClick={() => {
                                    setactiveTab(1)
                                  }}
                                  disabled={!(passedSteps || []).includes(1)}
                                >
                                  <span className="number">1.</span> Store Admin
                                  Details
                                </NavLink>
                              </NavItem>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 2,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 2,
                                  })}
                                  onClick={() => {
                                    setactiveTab(2)
                                  }}
                                  disabled={!(passedSteps || []).includes(2)}
                                >
                                  <span className="number ms-2">02</span> Store
                                  Details
                                </NavLink>
                              </NavItem>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 3,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 3,
                                  })}
                                  onClick={() => {
                                    setactiveTab(3)
                                  }}
                                  disabled={!(passedSteps || []).includes(3)}
                                >
                                  <span className="number">03</span> Shipping
                                  Details
                                </NavLink>
                              </NavItem>
                              {/* <NavItem
                                className={classnames({
                                  current: activeTab === 4,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 4,
                                  })}
                                  onClick={() => {
                                    setactiveTab(4)
                                  }}
                                  disabled={!(passedSteps || []).includes(4)}
                                >
                                  <span className="number">04</span> Working
                                  Hours
                                </NavLink>
                              </NavItem> */}
                            </ul>
                          </div>
                          <div className="content clearfix mt-4">
                            <TabContent activeTab={activeTab}>
                              <TabPane tabId={1}>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.name"
                                        label="Name *"
                                        type="text"
                                        errorMessage="Invalid Name"
                                        validate={{
                                          required: { value: true },
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.phone"
                                        label="Contact No. *"
                                        type="text"
                                        errorMessage="Invalid phone no."
                                        validate={{
                                          required: { value: true },
                                          maxLength: {
                                            value: 10,
                                            errorMessage:
                                              "phone number must be 10 digits",
                                          },
                                          minLength: {
                                            value: 10,
                                            errorMessage:
                                              "phone number must be 10 digits",
                                          },
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.email"
                                        label="Email *"
                                        type="email"
                                        errorMessage="Invalid email"
                                        validate={{
                                          required: { value: true },
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.password"
                                        label="Password"
                                        type="Password"
                                        errorMessage="Invalid password"
                                        validate={{
                                          required: { value: true },
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.addressLine1"
                                        label="Address line 1"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.addressLine2"
                                        label="Address line 2"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.landmark"
                                        label="Landmark"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.area"
                                        label="Area"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.district"
                                        label="District"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.state"
                                        label="State"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.country"
                                        label="Country"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAdminDetails.address.pinCode"
                                        label="Pin code"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId={2}>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeName"
                                        label="Store Name *"
                                        type="text"
                                        errorMessage="Invalid store name"
                                        validate={{
                                          required: { value: true },
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="3">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeNo"
                                        label="Store Number"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="phone"
                                        label="Contact Number"
                                        type="number"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="email"
                                        label="Email"
                                        type="email"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.addressLine1"
                                        label="Address line 1"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.addressLine2"
                                        label="Address line 2"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.landmark"
                                        label="Landmark"
                                        type="text"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.area"
                                        label="Area"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.district"
                                        label="District"
                                        type="text"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.state"
                                        label="State"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.country"
                                        label="Country"
                                        type="text"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6">
                                    <div className="mb-3">
                                      <AvField
                                        name="storeAddress.pinCode"
                                        label="Pin code"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                                    <MyGoogleMap
                                      MAP_PUBLIC_KEY={MAP_PUBLIC_KEY}
                                      setMarkers={setMarkers}
                                      markers={markers}
                                      height={300}
                                    />
                                  </Col>
                                  <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="col-12 mt-5 mx-5">
                                      <Label>
                                        <i className="bx bx-map"></i> Location(
                                        lat / lng )
                                      </Label>
                                    </div>
                                    <div className="col-6 mb-3 mt-2 mx-5">
                                      <AvField
                                        name="location.coordinates[0]"
                                        placeholder="Latitude"
                                        type="text"
                                        id="#geolocation"
                                        errorMessage="Invalid latitude"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={markers[0]?.lat}
                                      />
                                    </div>
                                    <div className="col-6 mb-3 mt-3 mx-5">
                                      <AvField
                                        name="location.coordinates[1]"
                                        placeholder="Longitude"
                                        type="text"
                                        errorMessage="Invalid longitude"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={markers[0]?.lng}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>

                              {/* <TabPane tabId={3}>
                                <Col className="col-12 d-flex flex-wrap">
                                  {!isOpen24hrs && !isCommonTiming && (
                                    <div className="col-4 mb-3">
                                      <Label check for="checkbox">
                                        Is Open EveryDay :
                                      </Label>
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2 mx-3"
                                        onColor="#626ed4"
                                        onChange={v => {
                                          setIsOpenEveryDay(v)
                                        }}
                                        checked={isOpenEveryDay}
                                      />
                                    </div>
                                  )}
                                  {!isOpenEveryDay && !isCommonTiming && (
                                    <div className="col-4 mb-3">
                                      <Label check for="checkbox">
                                        Is Open 24Hrs :
                                      </Label>
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2 mx-3"
                                        onColor="#626ed4"
                                        onChange={v => {
                                          setIsOpen24hrs(v)
                                        }}
                                        checked={isOpen24hrs}
                                      />
                                    </div>
                                  )}
                                  {!isOpenEveryDay && !isOpen24hrs && (
                                    <div className="col-4 mb-3">
                                      <Label check for="checkbox">
                                        Is Common Timing :
                                      </Label>
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2 mx-3"
                                        onColor="#626ed4"
                                        onChange={v => {
                                          setIsCommonTiming(v)
                                        }}
                                        checked={isCommonTiming}
                                      />
                                    </div>
                                  )}
                                </Col>
                                {isCommonTiming && (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <button
                                        className="btn bg-light d-flex align-items-center justify-content-center"
                                        onClick={e => {
                                          e.preventDefault()
                                          setCommonTiming(currentTiming => [
                                            ...currentTiming,
                                            {
                                              from: "",
                                              to: "",
                                            },
                                          ])
                                        }}
                                      >
                                        <i
                                          className="bx bx-plus"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                      </button>
                                    </div>
                                    <Row className="pt-3">
                                      <Col md="2">
                                        <h6>From</h6>
                                      </Col>
                                      <Col md="2">
                                        <h6>To</h6>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                                {isCommonTiming &&
                                  commonTiming?.map((itm, key) => (
                                    <Row key={key} className="mb-2">
                                      <Col md="2">
                                        <AvField
                                          name="varient.value"
                                          type="time"
                                          value={itm.value}
                                          onChange={e => {
                                            const from = e.target.value
                                            setCommonTiming(currentTiming =>
                                              currentTiming.map(x =>
                                                x === itm
                                                  ? {
                                                      ...x,
                                                      from,
                                                    }
                                                  : x
                                              )
                                            )
                                          }}
                                        />
                                      </Col>
                                      <Col md="2">
                                        <AvField
                                          name="varient.value"
                                          type="time"
                                          value={itm.value}
                                          onChange={e => {
                                            const to = e.target.value
                                            setCommonTiming(currentTiming =>
                                              currentTiming.map(x =>
                                                x === itm
                                                  ? {
                                                      ...x,
                                                      to,
                                                    }
                                                  : x
                                              )
                                            )
                                          }}
                                        />
                                      </Col>
                                      <Col md="2">
                                        <button
                                          className="btn"
                                          onClick={e => {
                                            e.preventDefault()
                                            setCommonTiming(currentTiming =>
                                              currentTiming.filter(
                                                x => x !== itm
                                              )
                                            )
                                          }}
                                        >
                                          <i
                                            className="bx bx-trash"
                                            style={{
                                              fontSize: "20px",
                                            }}
                                          ></i>
                                        </button>
                                      </Col>
                                    </Row>
                                  ))}
                                {!isCommonTiming &&
                                  (isOpenEveryDay || isOpen24hrs) && (
                                    <div className="d-flex align-items-center">
                                      <button
                                        className="btn bg-light d-flex align-items-center justify-content-center"
                                        onClick={e => {
                                          e.preventDefault()
                                          setDayWiseTiming(currentTiming => [
                                            ...currentTiming,
                                            {
                                              day: "",
                                              isClosed: false,
                                              timing: [
                                                {
                                                  from: "",
                                                  to: "",
                                                },
                                              ],
                                            },
                                          ])
                                        }}
                                      >
                                        <i
                                          className="bx bx-plus"
                                          style={{ fontSize: "20px" }}
                                        ></i>
                                      </button>
                                    </div>
                                  )}
                                {!isCommonTiming &&
                                  (isOpenEveryDay || isOpen24hrs) &&
                                  dayWiseTiming?.map((itm, key) => (
                                    <Row key={key} className="mb-2 mt-3">
                                      <Col md="1" xs="2">
                                        <button
                                          className="btn btn_wh"
                                          onClick={e => {
                                            e.preventDefault()
                                            setDayWiseTiming(currentTiming =>
                                              currentTiming.filter(
                                                x => x !== itm
                                              )
                                            )
                                          }}
                                        >
                                          <i
                                            className="bx bx-trash  text-danger"
                                            style={{
                                              fontSize: "22px",
                                            }}
                                          ></i>
                                        </button>
                                      </Col>
                                      <Col md="3" xs="6">
                                        <Label>Day</Label>
                                        <AvField
                                          name="varient.value"
                                          type="select"
                                          value={itm.value}
                                          onChange={e => {
                                            const day = e.target.value
                                            setDayWiseTiming(currentTiming =>
                                              currentTiming.map(x =>
                                                x === itm
                                                  ? {
                                                      ...x,
                                                      day,
                                                    }
                                                  : x
                                              )
                                            )
                                          }}
                                        >
                                          <option value={null}>select</option>
                                          {days?.map((item, key) => (
                                            <option key={key} value={item}>
                                              {item}
                                            </option>
                                          ))}
                                        </AvField>
                                      </Col>
                                      <Col md="3" xs="2">
                                        <div className="col-4 mb-3">
                                          <Label>Is Closed:</Label>
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2 mx-3"
                                            onColor="#626ed4"
                                            onChange={e => {
                                              const isClosed = e
                                              setDayWiseTiming(currentTiming =>
                                                currentTiming.map(x =>
                                                  x === itm
                                                    ? {
                                                        ...x,
                                                        isClosed,
                                                      }
                                                    : x
                                                )
                                              )
                                            }}
                                            checked={itm?.isClosed}
                                          />
                                        </div>
                                      </Col>
                                      {!itm?.isClosed && (
                                        <>
                                          <Col
                                            md="1"
                                            className="d-flex justify-content-end"
                                          >
                                            <button
                                              style={{
                                                height: "30px",
                                                width: "30px",
                                              }}
                                              className="btn bg-light d-flex align-items-center justify-content-center mb-2 btn_wh"
                                              onClick={e => {
                                                e.preventDefault()
                                                setDayWiseTiming(
                                                  currentTiming => [
                                                    ...currentTiming.map(
                                                      time => ({
                                                        ...time,
                                                        timing: [
                                                          ...time.timing,
                                                          {
                                                            from: "",
                                                            to: "",
                                                          },
                                                        ],
                                                      })
                                                    ),
                                                  ]
                                                )
                                              }}
                                            >
                                              <i
                                                className="bx bx-plus"
                                                style={{ fontSize: "20px" }}
                                              ></i>
                                            </button>
                                          </Col>
                                          <Col md="4">
                                            {itm?.timing.map((time, key) => (
                                              <Row key={key} className="mb-2">
                                                <Col md="5" xs="5">
                                                  <Label>From</Label>
                                                  <AvField
                                                    name="varient.value"
                                                    type="time"
                                                    value={time.value}
                                                    helpMessage="24hr Format"
                                                    onChange={e => {
                                                      const from =
                                                        e.target.value
                                                      setDayWiseTiming(
                                                        currentTiming => [
                                                          ...currentTiming.map(
                                                            day => ({
                                                              ...day,
                                                              timing: [
                                                                ...day.timing?.map(
                                                                  x =>
                                                                    x === time
                                                                      ? {
                                                                          ...x,
                                                                          from,
                                                                        }
                                                                      : x
                                                                ),
                                                              ],
                                                            })
                                                          ),
                                                        ]
                                                      )
                                                    }}
                                                  />
                                                </Col>
                                                <Col md="5" xs="5">
                                                  <Label>To</Label>
                                                  <AvField
                                                    name="varient.value"
                                                    type="time"
                                                    value={time.value}
                                                    helpMessage="24hr Format"
                                                    onChange={e => {
                                                      const to = e.target.value
                                                      setDayWiseTiming(
                                                        currentTiming => [
                                                          ...currentTiming.map(
                                                            day => ({
                                                              ...day,
                                                              timing: [
                                                                ...day.timing?.map(
                                                                  x =>
                                                                    x === time
                                                                      ? {
                                                                          ...x,
                                                                          to,
                                                                        }
                                                                      : x
                                                                ),
                                                              ],
                                                            })
                                                          ),
                                                        ]
                                                      )
                                                    }}
                                                  />
                                                </Col>
                                                <Col md="2" xs="2">
                                                  <button
                                                    className="btn btn_wh"
                                                    onClick={e => {
                                                      e.preventDefault()
                                                      setDayWiseTiming(
                                                        currentTiming => [
                                                          ...currentTiming.map(
                                                            day => ({
                                                              ...day,
                                                              timing: [
                                                                ...day.timing.filter(
                                                                  x =>
                                                                    x !== time
                                                                ),
                                                              ],
                                                            })
                                                          ),
                                                        ]
                                                      )
                                                    }}
                                                  >
                                                    <i
                                                      className="bx bx-trash"
                                                      style={{
                                                        fontSize: "18px",
                                                      }}
                                                    ></i>
                                                  </button>
                                                </Col>
                                              </Row>
                                            ))}
                                          </Col>
                                        </>
                                      )}
                                    </Row>
                                  ))}
                              </TabPane> */}

                              <TabPane tabId={3}>
                                <div>
                                  <Row>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="defaultShippingCharge"
                                          label="Default Shipping Charge"
                                          type="number"
                                          value={
                                            shipping?.defaultShippingCharge ||
                                            ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="deliveryTimeLimit"
                                          label="Delivery Time Limit"
                                          type="time"
                                          value={
                                            shipping?.deliveryTimeLimit || ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="perKmShippingCharge"
                                          label="Per Km Shipping Charge"
                                          type="number"
                                          value={
                                            shipping?.perKmShippingCharge || ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    {/* <Col lg="3">
                                      <div className="mb-3">
                                        <AvField
                                          name="freeShippingCharge"
                                          label="Free Shipping Charge"
                                          value={freeShippingCharge}
                                          onChange={e =>
                                            setFreeShippingCharge(
                                              e.target.value
                                            )
                                          }
                                          type="number"
                                        />
                                      </div>
                                    </Col> */}
                                    {/* <Col lg="3">
                                      <div className="mb-3">
                                        <AvField
                                          name="minDeliveryCharge"
                                          label="min Delivery Charge"
                                          value={minDeliveryCharge}
                                          onChange={e =>
                                            setminDeliveryCharge(e.target.value)
                                          }
                                          type="number"
                                        />
                                      </div>
                                    </Col> */}
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="codCharge"
                                          label="COD Charge"
                                          type="number"
                                          value={shipping?.codCharge || ""}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="primaryBoundaryDistance"
                                          label="primary Boundary Distance"
                                          type="number"
                                          required
                                          value={
                                            shipping?.primaryBoundaryDistance ||
                                            ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="primaryBoundaryThresholdAmount"
                                          label="Primary Boundary Threshold Amount"
                                          type="number"
                                          required
                                          value={
                                            shipping?.primaryBoundaryThresholdAmount ||
                                            ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="secondaryBoundaryDistance"
                                          label="Secondary Boundary Distance"
                                          type="number"
                                          required
                                          value={
                                            shipping?.secondaryBoundaryDistance ||
                                            ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="secondaryBoundaryThresholdAmount"
                                          label="Secondary Boundary Threshold Amount"
                                          type="number"
                                          required
                                          value={
                                            shipping?.secondaryBoundaryThresholdAmount ||
                                            ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  {/* <Col className="col-12 d-flex flex-wrap">
                                    <div className="col-6 mb-3">
                                      <Label check for="checkbox">
                                        Is Direct Delivery :
                                      </Label>
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2 mx-3"
                                        onColor="#626ed4"
                                        onChange={v => {
                                          setIsDirectDelivery(v)
                                        }}
                                        checked={isDirectDelivery}
                                      />
                                    </div>
                                  </Col>
                                  {isDirectDelivery && (
                                    <>
                                      <Row>
                                        <Col lg="3">
                                          <div className="mb-3">
                                            <AvField
                                              name="directDeliverySettings.distanceUnit"
                                              label="Distance Unit"
                                              value={distanceUnit}
                                              onChange={e =>
                                                setDistanceUnit(e.target.value)
                                              }
                                              type="text"
                                            />
                                          </div>
                                        </Col>
                                        <Col lg="3">
                                          <div className="mb-3">
                                            <AvField
                                              name="directDeliverySettings.deliveryChargePerDistance"
                                              label="Delivery Charge Per Distance"
                                              value={deliveryChargePerDistance}
                                              onChange={e =>
                                                setDeliveryChargePerDistance(
                                                  e.target.value
                                                )
                                              }
                                              type="number"
                                            />
                                          </div>
                                        </Col>
                                      </Row>

                                      <Col className="col-12 d-flex flex-wrap">
                                        <div className="col-6 mb-3 mt-1">
                                          <Label check for="checkbox">
                                            Is Free Delivery Available :
                                          </Label>
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2 mx-3"
                                            onColor="#626ed4"
                                            onChange={v => {
                                              setIsFreeDeliveryAvailable(v)
                                            }}
                                            checked={isFreeDeliveryAvailable}
                                          />
                                        </div>
                                      </Col>
                                      {isFreeDeliveryAvailable && (
                                        <Row>
                                          <Col lg="3">
                                            <div className="mb-3">
                                              <AvField
                                                name="directDeliverySettings.freeDeliveryDistanceThreshold"
                                                label="Distance Threshold"
                                                value={
                                                  freeDeliveryDistanceThreshold
                                                }
                                                onChange={e =>
                                                  setFreeDeliveryDistanceThreshold(
                                                    e.target.value
                                                  )
                                                }
                                                type="number"
                                              />
                                            </div>
                                          </Col>
                                          <Col lg="3">
                                            <div className="mb-3">
                                              <AvField
                                                name="directDeliverySettings.freeDeliveryOrderValueThreshold"
                                                label="Order Value Threshold"
                                                value={
                                                  freeDeliveryOrderValueThreshold
                                                }
                                                onChange={e =>
                                                  setFreeDeliveryOrderValueThreshold(
                                                    e.target.value
                                                  )
                                                }
                                                type="number"
                                              />
                                            </div>
                                          </Col>
                                          <Col lg="3">
                                            <div className="mb-3">
                                              <AvField
                                                name="directDeliverySettings.maxDeliveryArea"
                                                label="Max Delivery Area"
                                                value={maxDeliveryArea}
                                                onChange={e =>
                                                  setMaxDeliveryArea(
                                                    e.target.value
                                                  )
                                                }
                                                type="number"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      )}
                                      <Col className="col-12 d-flex flex-wrap">
                                        <div className="col-6 mb-3 mt-1">
                                          <Label check for="checkbox">
                                            Is Near By Delivery Available :
                                          </Label>
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2 mx-3"
                                            onColor="#626ed4"
                                            onChange={v => {
                                              setIsNearByDeliveryAvailable(v)
                                            }}
                                            checked={isNearByDeliveryAvailable}
                                          />
                                        </div>
                                      </Col>
                                      {isNearByDeliveryAvailable && (
                                        <Row>
                                          <Col lg={6}>
                                            <div className="row mb-4">
                                              <Label
                                                htmlFor="institute_name"
                                                className="col-sm-3 col-form-label"
                                              >
                                                Max Delivery Area
                                              </Label>
                                              <Col sm={4}>
                                                <AvField
                                                  name="directDeliverySettings.maxNearByDeliveryArea"
                                                  value={maxNearByDeliveryArea}
                                                  onChange={e =>
                                                    setMaxNearByDeliveryArea(
                                                      e.target.value
                                                    )
                                                  }
                                                  type="number"
                                                />
                                              </Col>
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="row mb-4">
                                              <Label
                                                htmlFor="institute_name"
                                                className="col-sm-3 col-form-label"
                                              >
                                                Free Delivery Order Value
                                                Threshold
                                              </Label>
                                              <Col sm={4}>
                                                <AvField
                                                  name="directDeliverySettings.nearByFreeDeliveryOrderValueThreshold"
                                                  value={
                                                    nearByFreeDeliveryOrderValueThreshold
                                                  }
                                                  onChange={e =>
                                                    setNearByFreeDeliveryOrderValueThreshold(
                                                      e.target.value
                                                    )
                                                  }
                                                  type="number"
                                                />
                                              </Col>
                                            </div>
                                          </Col>

                                          <Col lg="6">
                                            <div className="row mb-3">
                                              <AvField
                                                name="directDeliverySettings.maxNearByDeliveryArea"
                                                label="Max Delivery Area"
                                                value={maxNearByDeliveryArea}
                                                onChange={e =>
                                                  setMaxNearByDeliveryArea(
                                                    e.target.value
                                                  )
                                                }
                                                type="number"
                                              />
                                            </div>
                                          </Col>

                                          <Col lg="6">
                                            <div className="mb-3">
                                              <AvField
                                                name="directDeliverySettings.nearByFreeDeliveryOrderValueThreshold"
                                                label="Free Delivery Order Value Threshold"
                                                value={
                                                  nearByFreeDeliveryOrderValueThreshold
                                                }
                                                onChange={e =>
                                                  setNearByFreeDeliveryOrderValueThreshold(
                                                    e.target.value
                                                  )
                                                }
                                                type="number"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      )}
                                    </>
                                  )} */}
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                          <div className="actions clearfix">
                            <ul>
                              <li
                                className={
                                  activeTab === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                <Link
                                  to="#"
                                  className="btn w-sm btn-md"
                                  onClick={() => {
                                    toggleTab(activeTab - 1)
                                  }}
                                >
                                  Previous
                                </Link>
                              </li>
                              <li
                                className={
                                  activeTab === 3 ? "next disabled" : "next"
                                }
                              >
                                {" "}
                                {activeTab !== 3 && (
                                  <Link
                                    to="#"
                                    className="btn w-sm btn-md"
                                    onClick={() => {
                                      toggleTab(activeTab + 1)
                                    }}
                                  >
                                    Next
                                  </Link>
                                )}
                              </li>
                              {activeTab === 3 && (
                                <li>
                                  <Button
                                    type="submit"
                                    color="success"
                                    className="w-sm btn-md btn-block"
                                  >
                                    {loading && (
                                      <>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                      </>
                                    )}{" "}
                                    Create Store
                                  </Button>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              </AvForm>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateStore

CreateStore.propTypes = {
  history: PropTypes.object,
}

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}
