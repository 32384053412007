import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import {
  Button,
  Card,
  NavItem,
  NavLink,
  CardBody,
  Col,
  Container,
  Nav,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap"
import {
  getPromoterProductDetails,
  getPromoterProfileDetails,
} from "store/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import classnames from "classnames"
import { Markup } from "interweave"
import { Notification } from "components/Common/Notification"
import MetaTag from "components/Common/Meta-tag"

function promoterProductDetails() {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, products, promoterProfile } = useSelector(state => ({
    loading: state.Promoter.productLoading,
    products: state?.Promoter?.promoterProductDetails,
    promoterProfile: state?.Dashboard?.promoterProfile,
  }))

  useEffect(() => {
    dispatch(getPromoterProductDetails(params?.id))
    dispatch(getPromoterProfileDetails())
  }, [dispatch])

  const [imageShow, setImageShow] = useState(products?.images?.[0]?.url)

  useEffect(() => {
    setImageShow(products?.images?.[0]?.url)
  }, [products])

  //
  const handleCopy = () => {
    const removeSpace = products?.primaryLang?.name?.replace(/ /g, "-")
    const text = `https://chocochi.in/products/${removeSpace}?ref_id=${promoterProfile?.ref_id}`
    navigator.clipboard?.writeText(text)
    Notification({
      type: "success",
      message: "URL Copied to clipboard",
      title: "",
    })
  }
  return (
    <div>
      <MetaTag title="Products/Product-Details" />

      <div className="page-content ">
        <Breadcrumbs
          title="Promoter Dashboard"
          breadcrumbItem="Product Details"
        />
        <Container fluid>
          <div className="container-fluid ">
            <Card>
              <CardBody>
                <>
                  <Row>
                    <Col xl="6">
                      <div className="product-detai-imgs">
                        <Row>
                          <Col md="2" xs="3">
                            <Nav className="flex-column" pills>
                              <NavItem>
                                <NavLink>
                                  <img
                                    src={products?.images?.[0].url}
                                    alt=""
                                    onClick={() => {
                                      setImageShow(products?.images?.[0].url)
                                    }}
                                    className="img-fluid mx-auto d-block rounded"
                                  />
                                </NavLink>
                              </NavItem>{" "}
                              <NavItem>
                                <NavLink>
                                  <img
                                    src={products?.images?.[1]?.url}
                                    alt=""
                                    onClick={() => {
                                      setImageShow(products?.images?.[1]?.url)
                                    }}
                                    className="img-fluid mx-auto d-block rounded"
                                  />
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink>
                                  <img
                                    src={products?.images?.[2]?.url}
                                    alt=""
                                    onClick={() => {
                                      setImageShow(products?.images?.[2]?.url)
                                    }}
                                    className="img-fluid mx-auto d-block rounded"
                                  />
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </Col>
                          <Col md={{ size: 7, offset: 1 }} xs="9">
                            <div>
                              <img
                                src={imageShow}
                                alt=""
                                id="expandedImg1"
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <Link to="#" className="text-primary">
                          {products?.category}
                        </Link>

                        <p className="text-muted mb-4 h3">
                          {products?.primaryLang?.name}
                        </p>

                        {!!products.isOffer && (
                          <h6 className="text-success text-uppercase">
                            {products.offer} % Off
                          </h6>
                        )}
                        <h5 className="mb-4">
                          <span className="text-muted me-2">
                            <del className="text-danger font-size-14">
                              ₹{products.price}{" "}
                            </del>
                          </span>{" "}
                          <b className="text-success font-size-20">
                            ₹{products.offerPrice}{" "}
                          </b>
                        </h5>
                        <p className="text-muted mb-4">
                          <Markup
                            content={products?.primaryLang?.description}
                          />
                        </p>
                        <div>
                          <Button
                            type="button"
                            className="px-5"
                            color="success"
                            onClick={() => handleCopy()}
                          >
                            Copy Url
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default promoterProductDetails
